import * as React from 'react'

import { KvikaSVGProps } from './types'

const CollapsableArrow = ({
	height = 16,
	width = 16,
	fill = 'currentColor',
	rotate = 0,
}: KvikaSVGProps) => (
	<svg
		width={width}
		height={height}
		fill='none'
		viewBox='0 0 18 10'
		xmlns='http://www.w3.org/2000/svg'
		style={{ transform: `rotate(${rotate}deg)` }}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='m8.293.293-8 8 1.414 1.414L9 2.414l7.293 7.293 1.414-1.414-8-8a1 1 0 0 0-1.414 0Z'
			fill={fill}
		/>
	</svg>
)

export default CollapsableArrow
