import React from 'react'
import styled from 'styled-components'

import FileIconAnchor from '../FileIconAnchor'

interface Props {
	children: React.ReactNode
	href: string
	showIcon?: boolean
}

const MediaLink: React.FC<Props> = ({ children, href, showIcon = true }) => {
	return (
		<Wrapper className='richtext-list'>
			<Text>
				<FileIconAnchor showIcon={showIcon} href={href}>
					{children}
				</FileIconAnchor>
			</Text>
		</Wrapper>
	)
}

export default MediaLink

const Wrapper = styled.span`
	border-top: 1px solid ${({ theme }) => theme.colors.grey100};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
	display: flex;
	align-items: center;
`
const Text = styled.span`
	display: flex;
	margin: 16px 0;
	margin-inline: 6px;
	a {
		color: ${({ theme }) => theme.colors.gold550};
		text-decoration: none;
	}
`
