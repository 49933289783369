export const Translations = {
	is: {
		general: {
			rate: 'Gengi',
			seeMore: 'Sjá meira',
			seeResume: 'Nánar',
			loadingError:
				'Ekki tókst að sækja vísitölu, ýttu á hnappin til að reyna aftur',
			tryAgain: 'Reyna aftur',
			back: 'Til baka',
			loading: 'Hleðsla',
			older: 'Eldra',
			close: 'Loka',
			fetchErrorTitle: 'Villa við að sækja gögn',
			fetchErrorMessage: 'Vinsamlegast reyndu aftur síðar.',
			noData: 'Engin gögn',
		},
		ranges: {
			oneMonth: '1 mán',
			threeMonths: '3 mán',
			sixMonths: '6 mán',
			ytd: 'Frá áramótum',
			all: 'Frá upphafi',
		},
		search: {
			pages: 'Síður',
			textResults: 'Textaniðurstöður',
			searchPlaceholder: 'Leita...',
			search: 'Leita',
			seeAllResults: 'Sjá allar niðurstöður',
			result: 'niðurstaða',
			results: 'niðurstöður',
		},
		contactForm: {
			kvika: {
				sections: {
					complaint: 'KVÖRTUN',
					message: 'SKILABOÐ',
				},
				labels: {
					name: 'Nafn',
					email: 'Netfang',
					subject: 'Efni skilaboða',
					inquiry: 'Fyrirspurn / Ábending',
					attachment: 'Viðhengi',
					attachmentButton: 'Velja skjal',
					inquiryType: 'Tegund fyrirspurnar',
					submit: 'Senda skilaboð',
					anonymousComplaint: 'Nafnlaus ábending',
				},
				inquiryTypes: {
					general: 'Almenn fyrirspurn',
					complaint: 'Kvörtun',
					compliments: 'Hrós',
				},
				validationErrors: {
					email: 'Netfang verður að vera gilt netfang',
					subject: 'Efni skilaboða er áskilinn reitur',
					message: 'Fyrirspurn / Ábending er áskilinn reitur',
					name: 'Nafn er áskilinn reitur',
					files: 'Skrár er áskilinn reitur',
				},
				notification: {
					successMessage: 'Skilaboðin þín hafa verið send',
					successTitle: 'Formi skilað',
					errorMessage: 'Eitthvað fór úrskeiðis, vinsamlegast reyndu aftur',
					errorTitle: 'Villa',
					invalidFileFormat: 'Ekki leyfilegt skjalasnið á viðhengi',
				},
			},
		},
		indexes: {
			indexTabs: {
				returns: 'Ávöxtun',
				methodology: 'Aðferðafræði',
				composition: 'Samsetning',
			},
			composition: {
				composition: 'Samsetning',
				largestIssuer: 'Stærstu útgefendur',
				metrics: 'Tölulegar upplýsingar',
				issuer: 'Útgefandi',
				total: 'Samtals',
			},
			financial: {
				index: 'Vísitala',
				valid: 'Gildi',
				dagsbr: 'Dagsbr.',
				oneMonth: '1 mán.',
				withinAYear: 'Innan Árs*',
				oneYear: '1 ár',
				yearInterval: 'Árs bil',
			},
			metrics: {
				marketValue: 'Markaðsvirði',
				marketValueMa: 'Markaðsvirði',
				indexedWeight: 'Verðtryggingahlutfall vísitölu',
				indexedWeightBonds: 'Verðtryggingahlutfall skuldabréfa',
				durationWeightIndexAvg: 'Meðallíftími vísitölu',
				durationWeightBondsAvg: 'Meðallíftími skuldabréfa',
				bondWeight: 'Bond weight', //TODO fá þýðingu á þetta
			},
		},
		navigation: {
			menu: 'Valmynd',
			onlineBank: 'Netbankinn',
			onlineBanks: 'Netbanki',
			Lang: 'EN',
			kvikaIndividuals: 'Kvika - Einstaklingar',
			kvikaCompany: 'Kvika - Fyrirtæki',
			kvikaAudur: 'Auður - dóttir Kviku',
		},
		breadcrumb: {
			home: 'Forsíða',
		},
		investorInformation: {
			annualReports: 'Uppgjör og ársskýrslur',
			shareholderList: 'Hluthafalisti',
			funding: 'Fjármögnun',
			news: 'Fréttir úr kauphöll',
			historicalDocumentsTmAndLykill: 'Söguleg gögn TM og Lykils',
			annualReportsTitle: 'Ársreikningar og uppgjör',
			shareholderMeetings: 'Hluthafafundir',
			fundingTitle: 'Fjármögnun',
			financialInformation: 'Fjárhagsupplýsingar',
		},
		calculator: {
			enterNumber: 'Sláðu inn tölu',
			generalExchangeRate: 'Almennt gengi',
			banknoteExchangeRate: 'Seðlagengi',
			visaExchangeRate: 'Gengi VISA',
			currency: 'Gjaldmiðill',
			buy: 'Kaup',
			sell: 'Sala',
			currencyConverter: 'Myntbreytir',

			visaInformationHTML: `
				Gengi debet og kreditkortafærslna í erlendri mynt er byggt á grunngengi VISA sem er að finna <a href='https://usa.visa.com/support/consumer/travel-support/exchange-rate-calculator.html'>hér</a> 2,6% gengisálag er innifalið í birtu gengi hér að ofan og er það sama fyrir allar myntir. Gengið er uppfært daglega.
			`,
			generalExchangeRateInformation: 'Almennt gengi bankans ákvarðast af gengi gjaldmiðla á millibankamarkaði með frádragi eða álagi eftir markaðsaðstæðum hverju sinni. Almennt gengi bankans er uppfært reglulega innan dags.',
			banknoteExchangeRateInformationHTML: `Seðlagengi bankans miðast við miðgengi Seðlabanka Íslands sem finna má <a href='https://www.sedlabanki.is/hagtolur/opinber-gengisskraning/'>hér</a>, með frádragi eða álagi eftir markaðsaðstæðum hverju sinni. Seðlagengi bankans er uppfært reglulega innan dags.`,

			generalExchangeRateDisclaimer: 'Upplýsingar á þessari síðu eru samkvæmt bestu vitund Kviku og ætlaðar til upplýsingagjafar. Kvika ber ekki ábyrgð á hugsanlegum villum eða töfum upplýsinga sem ekki verða raktar til Kviku og/eða ákvörðunum byggðum á þeim.',


		},
		cookieConsent: {
			saveSettings: 'Vista stillingar',
			settings: 'Stillingar vefkaka',
			requiredTitle: 'Nauðsynlegar vefkökur',
			requiredDescription:
				'Sumar vefkökur eru nauðsynlegar til að vefsvæðið virki eðlilega og eru þær sjálfkrafa virkar',
			statisticsTitle: 'Tölfræði',
			statisticsDescription:
				'Tölfræðilegar vefkökur hjálpa okkur að bæta heildarupplifun gesta á vefsvæðinu með því að safna upplýsingumum notkun.',
			marketingTitle: 'Markaðssetning',
			marketingDescription:
				'Vefkökur fyrir markaðssetningu eru notaðar til að safna upplýsingum um hegðun notanda til að geta birtviðeigandi auglýsingar.',
			reject: 'Hafna',
			allow: 'Leyfa',
			toggle: 'Skipta',
			cookieDescription:
				'Kvika.is notar vefkökur til að bæta upplifun og greina umferð umvefinn.',
			moreDescription: 'Nánar um vefkökunotkun',
			agree: 'Samþykkja',
		},
		shareholders: {
			listDate:
				'Neðangreindur listi sýnir hluthafa Kviku með eignarhlut yfir 1% þann {{date}}',
			shareTypes:
				'Aðeins einn hlutaflokkur er í félaginu, hlutafé félagsins er samtals kr. {{sharesTotal}} að nafnvirði og atkvæði eru jafn mörg.',
			name: 'Nafn hluthafa',
			shareNumber: 'Fjöldi hluta',
			holdings: 'Eignarhlutur',
			owners: 'Skráðir eigendur',
		},
		slices: {
			seeNews: 'Sjá allar frettir',
			more: 'Nánar',
			linkList: {
				viewAllDocs: 'Sýna öll skjöl',
				collapseDocs: 'Loka',
			},
		},
		grantApplicationForm: {
			genderOptions: {
				man: 'Karl',
				woman: 'Kona',
				other: 'Annað',
			},
			educationOptions: {
				apprenticeship: 'Iðnnám',
				teacherTraining: 'Kennaranám',
			},
			educationStatusOptions: {
				start: 'Er að hefja nám',
				oneYearCompleted: 'Hef lokið einu ári',
				twoYearsCompleted: 'Hef lokið tveimur árum',
				threeOrMoreYearsCompleted: 'Hef lokið þremur eða fleiri árum',
			},
			labels: {
				fullName: 'Nafn',
				personalId: 'Kennitala',
				streetAddress: 'Heimilisfang',
				city: 'Staður',
				postalCode: 'Póstnúmer',
				email: 'Netfang',
				age: 'Aldur',
				gender: 'Kyn',
				phone: 'Símanúmer',
				typeOfEducation: 'Iðnnám eða kennaranám',
				educationStatus: 'Staða í núverandi námi',
				educationName: 'Nafn menntunar',
				submit: 'Senda umsókn',
				attachment: 'Viðhengi',
				attachmentButton: 'Velja Skrá',
			},
			placeholders: {
				fullName: 'Fullt nafn',
				personalId: 'Kennitala',
				streetAddress: 'Gata og númer',
				city: 'Staður',
				postalCode: 'Póstnúmer',
				email: 'Netfangið þitt',
				phone: '000-0000',
				age: 'Aldur í tölustöfum',
				gender: 'Veldu kyn',
				typeOfEducation: 'Veldu tegund náms',
				educationStatus: 'Veldu stöðu',
				educationName: 'Nafn menntunar',
				recaptcha: 'Skrifaðu svar þitt ',
			},
			sections: {
				message: 'Umsókn',
				formTitle: 'Upplýsingar um umsækjanda',
				supportingDocumentsTitle: 'Fylgigögn',
			},
			validationErrors: {
				email: 'Netfang verður að vera gilt netfang',
				age: 'Ógildur aldur',
				required: 'Reitur er áskilinn',
				recaptcha: 'Gildi er rangt',
				personalId: 'Kennitalan er ógild',
				missingFiles: 'Skjöl vantar',
			},
			notification: {
				successMessage:
					'Umsókn þín til Hvatningarsjóðs Kviku hefur verið móttekin. Þú færð einnig tölvupóst til staðfestingar.',
				successTitle: 'Umsókn móttekin',
				errorMessage: 'Eitthvað fór úrskeiðis, vinsamlegast reyndu aftur',
				errorTitle: 'Villa',
				invalidFileFormat: 'Ekki leyfilegt skjalasnið á viðhengi',
				failedFileUpload:
					'Ekki tókst að hlaða upp skjölum með umsókn. \n Vinsamlegast athugaðu hvort að skjalastærð sé undir mörkum (25MB) og að nöfn skjala séu í lagi.',
			},
		},
		recaptcha: {
			digits: {
				'0': 'núll',
				'1': 'einn',
				'2': 'tveir',
				'3': 'þrír',
				'4': 'fjórir',
				'5': 'fimm',
				'6': 'sex',
				'7': 'sjö',
				'8': 'átta',
				'9': 'níu',
			},
			minus: 'mínus',
			plus: 'plús',
			exrpressionStart: 'Hvað er ',
		},
		fileInput: {
			validationErrors: {
				size: 'Stærð skráar fer yfir mörkin',
				name: 'Ógilt skráarheiti',
				type: 'Ógild skráartegund',
				duplicateFiles: 'Tvö eða fleiri skjöl bera sama heiti',
			},
		},
	},
	en: {
		general: {
			rate: 'Rate',
			seeMore: 'See more',
			seeResume: 'See more',
			loadingError: 'Failed to retrieve index, press the button to retry',
			tryAgain: 'Try again',
			back: 'Back',
			loading: 'Loading',
			older: 'Older',
			close: 'Close',
			fetchErrorTitle: 'Error while loading data',
			fetchErrorMessage: 'Plase come back later to check again',
			noData: 'No data',
		},
		ranges: {
			oneMonth: '1M',
			threeMonths: '3M',
			sixMonths: '6M',
			ytd: 'YTD',
			all: 'All',
		},
		search: {
			pages: 'Pages',
			textResults: 'Text results',
			searchPlaceholder: 'Search...',
			search: 'Search',
			seeAllResults: 'See all results',
			result: 'result',
			results: 'results',
		},
		contactForm: {
			kvika: {
				sections: {
					complaint: 'COMPLAINT',
					message: 'MESSAGE',
				},
				labels: {
					name: 'Name',
					email: 'Email',
					subject: 'Subject',
					inquiry: 'Request / Inquiry',
					attachment: 'Attachment',
					inquiryType: 'Type of inquiry',
					attachmentButton: 'Select file',
					submit: 'Submit',
					anonymousComplaint: 'Anonymous complaint',
				},

				inquiryTypes: {
					general: 'General inquiry',
					complaint: 'Complaint',
					compliments: 'Compliments',
				},
				validationErrors: {
					email: 'Email must be valid',
					subject: 'Subject is required',
					message: 'Inquiry / Suggestion  is required',
					name: 'Name is required',
					files: 'Files are required',
				},
				notification: {
					successMessage: 'Your message has been sent successfully',
					successTitle: 'Success',
					errorMessage: 'Something went wrong, please try again',
					errorTitle: 'Error',
					invalidFileFormat: 'Invalid attachment format',
				},
			},
		},

		indexes: {
			indexTabs: {
				returns: 'Returns',
				methodology: 'Methodology',
				composition: 'Composition',
			},
			composition: {
				composition: 'Composition',
				largestIssuer: 'Largest issuers',
				metrics: 'Metrics',
				issuer: 'Issuer',
				total: 'Total',
			},
			financial: {
				index: 'Index',
				valid: 'Valid',
				dagsbr: 'Dagsbr.',
				oneMonth: '1 month',
				withinAYear: 'Within a year*',
				oneYear: '1 year',
				yearInterval: 'Year interval',
			},
			metrics: {
				marketValue: 'Market value',
				marketValueMa: 'Markaðsvirði',
				indexedWeight: 'Verðtryggingahlutfall vísitölu',
				indexedWeightBonds: 'Verðtryggingahlutfall skuldabréfa',
				durationWeightIndexAvg: 'Meðallíftími vísitölu',
				durationWeightBondsAvg: 'Meðallíftími skuldabréfa',
				bondWeight: 'Bond weight', //TODO fá þýðingu á þetta
			},
		},

		navigation: {
			menu: 'Menu',
			onlineBank: 'Online bank',
			onlineBanks: 'Online bank',
			Lang: 'EN',
			kvikaIndividuals: 'Kvika - Individuals',
			kvikaCompany: 'Kvika - Company',
			kvikaAudur: 'Auður - daughter of Kviku',
		},
		breadcrumb: {
			home: 'Home',
		},
		investorInformation: {
			annualReports: 'Annual Reports',
			shareholderList: 'List Of Shareholders',
			funding: 'Funding',
			news: 'Stock Exchange Releases',
			historicalDocumentsTmAndLykill: 'TM & Lykil Document Archive',
			annualReportsTitle: 'Annual Accounts And Settlements',
			shareholderMeetings: 'Shareholder Meetings',
			fundingTitle: 'Funding',
			financialInformation: 'Financial Information',
		},
		calculator: {
			enterNumber: 'Enter a number',
			generalExchangeRate: 'General exchange rate',
			banknoteExchangeRate: 'Banknote exchange rate',
			visaExchangeRate: 'VISA exchange rate',
			currency: 'Currency',
			buy: 'Buy',
			sell: 'Sell',
			currencyConverter: 'Currency converter',
		},
		cookieConsent: {
			saveSettings: 'Save settings',
			settings: 'Cookie settings',
			requiredTitle: 'Required cookies',
			requiredDescription:
				'Some cookies are necessary for the website to function properly and are automatically active',
			statisticsTitle: 'Statistics',
			statisticsDescription:
				'Statistical cookies help us improve the overall experience of visitors to the website by collecting usage information.',
			marketingTitle: 'Marketing',
			marketingDescription:
				'Marketing cookies are used to collect information about user behavior in order to display relevant ads.',
			reject: 'Reject',
			allow: 'Allow',
			toggle: 'Toggle',
			cookieDescription:
				'Kvika.is uses cookies to improve the experience and analyze the traffic around the web.',
			moreDescription: 'More about cookie usage',
			agree: 'Agree',
		},
		shareholders: {
			listDate:
				"The list below shows Kvika's shareholders with a holding of more than 1% as of {{date}}",
			shareTypes:
				"There is only one share class in the company, the company's share capital is a total of {{sharesTotal}} ISK.",
			nominalText: 'nominal value and votes are equal.',
			name: 'Shareholder name',
			shareNumber: 'Number of shares',
			holdings: 'Holdings',
			owners: 'Registered owners',
		},
		slices: {
			seeNews: 'See all the news',
			more: 'More',
			linkList: {
				viewAllDocs: 'View all documents',
				collapseDocs: 'Close',
			},
		},
		grantApplicationForm: {
			genderOptions: {
				man: 'Man',
				woman: 'Woman',
				other: 'Other',
			},
			educationOptions: {
				apprenticeship: 'Apprenticeship',
				teacherTraining: 'Teacher training',
			},
			educationStatusOptions: {
				start: 'Is starting studies',
				oneYearCompleted: 'Have completed one year',
				twoYearsCompleted: 'Have completed two years',
				threeOrMoreYearsCompleted: 'Have completed three or more years',
			},
			labels: {
				fullName: 'Full name',
				personalId: 'Personal ID',
				streetAddress: 'Street address',
				city: 'City',
				postalCode: 'Postal code',
				email: 'Email',
				age: 'Age',
				gender: 'Gender',
				phone: 'Phone number',
				typeOfEducation: 'Type of education',
				educationStatus: 'Education status',
				educationName: 'Education name',
				submit: 'Submit application',
				attachment: 'Attachment',
				attachmentButton: 'Select file',
			},
			placeholders: {
				fullName: 'Full name',
				personalId: 'Personal ID',
				streetAddress: 'Street and number',
				city: 'City',
				postalCode: 'Postal code',
				email: 'Your email address',
				phone: '000-0000',
				age: 'Age in numbers',
				gender: 'Select gender',
				typeOfEducation: 'Select the type of education',
				educationStatus: 'Select an education status',
				educationName: 'Education name',
				recaptcha: 'Write your answer',
			},
			sections: {
				message: 'Application',
				formTitle: 'Information about the applicant',
				supportingDocumentsTitle: 'Supporting documents',
			},
			validationErrors: {
				email: 'Invalid email',
				age: 'Invalid age',
				required: 'Field is required',
				recaptcha: 'Value is incorrect',
				personalId: 'Value is incorrect',
				missingFiles: 'Files are missing',
			},
			notification: {
				successMessage:
					'Your application has been received. You will also receive a confirmation email',
				successTitle: 'Application recieved',
				errorMessage: 'Something went wrong, please try again',
				errorTitle: 'Error',
				invalidFileFormat: 'Invalid attachment format',
				failedFileUpload:
					'Failed to upload files. \n Please check that document size is below limit (25MB) and that documents names are correct',
			},
		},
		recaptcha: {
			digits: {
				'0': 'zero',
				'1': 'one',
				'2': 'two',
				'3': 'three',
				'4': 'four',
				'5': 'five',
				'6': 'six',
				'7': 'seven',
				'8': 'eight',
				'9': 'nine',
			},
			minus: 'minus',
			plus: 'plus',
			exrpressionStart: 'How much is ',
		},
		fileInput: {
			validationErrors: {
				size: 'File size exceeds the limit',
				name: 'Invalid file name',
				type: 'Invalid file type',
				duplicateFiles: 'Duplicate files',
			},
		},
	},
}
