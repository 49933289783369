import React from 'react'
import styled from 'styled-components'
import { HitsProvided } from 'react-instantsearch-core'
import { Highlight, Snippet } from 'react-instantsearch-dom';
import Link from 'next/link'

import { IHitProps } from '../../../types/Search'


interface IProps extends HitsProvided<IHitProps> {
  onHitClick?: (event: React.MouseEvent<HTMLElement>) => void
  className?: string
}


const SearchHits = ({ hits, onHitClick, className }: IProps) => {
  return (
    <div className={className}>
      {hits.map((hit) => (
        <Link href={hit.slug} passHref key={hit.objectID} legacyBehavior>
          <Anchor
            onClick={(event) => {
              onHitClick && onHitClick(event)
            }}
          >
            <Title><Highlight attribute='title' hit={hit} /></Title>
            <SmallParagraph><Snippet attribute='description' hit={hit} /></SmallParagraph>
          </Anchor>
        </Link>
      ))}
    </div>
  );
}

export default SearchHits


const Title = styled.h6`
  & em {
    background-color: ${({ theme: { colors } }) => colors.gold200};
    font-weight: 900;
  }
  color: ${({ theme: { colors } }) => colors.grey800};

  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
`

const Anchor = styled.a`
  display: block;
  background-color: white;
  transition: background-color 320ms ease;
  padding: 16px;
  &:focus,
  &:hover,
  &.focus-visible {
    background-color: ${({ theme: { colors } }) => colors.gold100};
  }
`

const SmallParagraph = styled.span`
  & em {
    background-color: ${({ theme: { colors } }) => colors.gold200};
    font-weight: 900;
  }
  color: ${({ theme: { colors } }) => colors.grey800};

  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
