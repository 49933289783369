import * as React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { SiteLogo } from '../icons'
import { media } from '../../utils/style-utils'
import { INavigation } from '../../types/prismic/pages/navigation'

import { HeaderNavigation } from './Navigation/HeaderNavigation'
import { StaticNavigation } from './Navigation/StaticNavigation'

export interface INewHeaderProps {
	headerNavigation: INavigation.IHeaderNavigationProps
}

export default function Header({ headerNavigation }: INewHeaderProps) {
	return (
		<StyledHeader>
			<Wrapper>
				<Link href='/'>
					<SiteLogo />
				</Link>
				<HeaderNavigation headerNavigation={headerNavigation} />
				<StaticNavigation />
			</Wrapper>
		</StyledHeader>
	)
}

const StyledHeader = styled.header`
	position: sticky;
	top: 0;
	height: var(--spacer-base);
	width: 100%;
	z-index: 500;
`

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px;
	z-index: 500;
	${media.phone`
		padding: var(--spacer-xs);
	`}
`
