import * as React from 'react'

import { KvikaSVGProps } from './types'

const Globe = ({
	width = 16,
	height = 16,
	fill = 'currentColor',
	stroke = 'currentColor',
}: KvikaSVGProps): JSX.Element => (
	<svg
		width={width}
		height={height}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M13.536 5.3h.87l-.438-.751a6.924 6.924 0 0 0-3.734-3.07l-1.21-.414.608 1.125c.46.85.813 1.77 1.06 2.734l.096.376H13.536ZM8.411 1.348 8 .753l-.411.595a11.77 11.77 0 0 0-1.596 3.308L5.8 5.3h4.4l-.193-.644A11.769 11.769 0 0 0 8.41 1.348ZM1.323 9.72l.095.379H5.076l-.068-.56A12.721 12.721 0 0 1 4.9 8c0-.515.045-1.02.108-1.54l.068-.56H1.418l-.095.379A7.092 7.092 0 0 0 1.1 8c0 .6.087 1.177.223 1.721Zm1.141.979h-.867l.434.75a6.889 6.889 0 0 0 3.735 3.071l1.21.414-.608-1.125c-.46-.85-.813-1.77-1.06-2.734l-.096-.376H2.464Zm2.36-5.4h.388l.096-.376c.247-.963.6-1.885 1.06-2.734l.608-1.125-1.21.414a6.89 6.89 0 0 0-3.735 3.07l-.434.751h3.227Zm2.765 9.352.411.595.411-.595a11.769 11.769 0 0 0 1.596-3.308l.194-.644H5.8l.193.644a11.77 11.77 0 0 0 1.596 3.308ZM9.872 10.1h.436l.06-.432c.073-.537.132-1.09.132-1.668 0-.577-.06-1.139-.133-1.669l-.06-.431H5.692l-.06.431C5.56 6.861 5.5 7.423 5.5 8s.06 1.131.133 1.668l.059.432H9.872Zm-.24 3.71-.608 1.125 1.21-.414a6.923 6.923 0 0 0 3.734-3.07l.437-.751h-3.617l-.096.376c-.247.963-.6 1.885-1.06 2.734Zm1.36-4.27-.068.56H14.582l.095-.379A7.095 7.095 0 0 0 14.9 8c0-.6-.087-1.177-.223-1.721l-.095-.379H10.924l.068.56c.063.52.108 1.025.108 1.54 0 .515-.045 1.02-.108 1.54ZM.5 8C.5 3.86 3.853.5 7.992.5 12.14.5 15.5 3.86 15.5 8c0 4.14-3.36 7.5-7.508 7.5C3.852 15.5.5 12.14.5 8Z'
			fill={fill}
			stroke={stroke}
		/>
	</svg>
)

export default Globe
