import * as React from 'react'
import { PrismicLink } from '@prismicio/react'
import styled from 'styled-components'
import Link from 'next/link'

import { IPrismicImage, PrismicImage } from './PrismicImage'
import { IPrismicLink } from './FooterLink'

export interface IFooterIconLinkSlice {
	slice_type: 'footericonlink'
	primary: {
		column: number
		icon: IPrismicImage
		// eslint-disable-next-line
		link_text: string
		link: IPrismicLink
	}
}

interface IProps {
	slice: IFooterIconLinkSlice
	linkResolver?: (link: any) => string | undefined
}

export const FooterIconLink = ({
	slice: {
		primary: {
			link,
			// eslint-disable-next-line
			link_text,
			icon,
		},
	},
	linkResolver,
}: IProps) => {
	const baseClassName = 'KUI-Prismic-ColumnFooter-FooterIconLink'
	const contents = (
		<>
			{icon && (
				<StyledPrismicImageWrap>
					<PrismicImage
						layout='fixed'
						image={icon}
						className={`${baseClassName}-Icon`}
					/>
				</StyledPrismicImageWrap>
			)}
			<span className={`${baseClassName}-Text`}>
				{
					// eslint-disable-next-line
					link_text
				}
			</span>
		</>
	)

	return link.url ? (
		<StyledPrismicLink
			// eslint-disable-next-line
			externalComponent={(props) => (
				<Link className={`${baseClassName}-Link`} {...props} />
			)}
			// eslint-disable-next-line
			internalComponent={(props) => (
				<Link className={`${baseClassName}-Link`} {...props} />
			)}
			field={link}
			linkResolver={linkResolver}
		>
			{contents}
		</StyledPrismicLink>
	) : (
		<div className={`${baseClassName}`}>{contents}</div>
	)
}

const StyledPrismicLink = styled(PrismicLink)`
	display: flex;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.gold400};

	&:hover {
		text-decoration: underline;
	}
`

const StyledPrismicImageWrap = styled.div`
	display: flex;
	width: 25px;
	justify-content: center;
	align-items: center;
`
