import { KvikaSVGProps } from "./types"

const ExtendArrow = ({
    fill= 'none', 
    height = 10, 
    width = 18
}: KvikaSVGProps) => (
    <svg 
        width={width}
        height={height} 
        viewBox="0 0 18 10" 
        fill={fill} 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M8.29297 9.70718L0.292969 1.70718L1.70718 0.292969L9.00008 7.58586L16.293 0.292969L17.7072 1.70718L9.70718 9.70718C9.31666 10.0977 8.68349 10.0977 8.29297 9.70718Z" 
            fill="#907140"
        />
    </svg>
)

export default ExtendArrow
