import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import styled from 'styled-components'

import useLocalization from '../../../hooks/utils/use-localization'
import { SegmentTrackingId, trackEvent } from '../../../utils/analytics'
import { usePrismicRoutingContext } from '../../../utils/prismic/Context'
import { getOtherLocaleURL } from '../../../utils/prismic/RouteHelpers'
import { media } from '../../../utils/style-utils'
import { Globe, Lock } from '../../icons'

export interface IStaticNavigationProps {}

export function StaticNavigation(props: IStaticNavigationProps) {
	const { translate } = useLocalization()
	const { currentPageId, pages } = usePrismicRoutingContext()
	const router = useRouter()
	const otherLocaleName = router.locale === 'is' ? 'en' : 'is'

	return (
		<>
			<Vr />
			<NavigationButton
				as='a'
				href='https://netbanki.kvika.is'
				onClick={() => {
					trackEvent({ event: SegmentTrackingId.OnlineBankClick })
				}}
			>
				<>
					<Lock />
					{translate('navigation.onlineBanks')}
				</>
			</NavigationButton>
			<NavigationButton
				onClick={() => {
					const path = getOtherLocaleURL(currentPageId, pages) ?? '/'
					router.push(path, path, {
						locale: otherLocaleName,
					})
				}}
			>
				<Globe />
				{otherLocaleName.toUpperCase()}
			</NavigationButton>
		</>
	)
}

const Vr = styled.div`
	height: var(--spacer-md);
	width: 1px;
	margin-left: 28px;
	background-color: ${(props) => props.theme.colors.gold200};

	${media.phone`
		margin-left: var(--spacer-xs);
	`}
`

export const NavigationButton = styled.a`
	display: flex;
	margin-left: 28px;
	padding: 8px 0;
	font-weight: 700;
	align-items: center;
	text-decoration: none;
	color: ${(props) => props.theme.colors.gold550};
	cursor: pointer;
	display: flex;

	&:hover {
		text-decoration: none;
	}

	svg {
		position: relative;
		margin-top: -3px;
		margin-right: 10px;
	}

	${media.phone`
    margin-left: var(--spacer-xs);
    font-size: 0.875rem;

    &:not(.mobileNavigationButton) {
        svg {
            display: none;
        }
    }
`}
`
