import React from 'react'
import styled from 'styled-components'

export interface IFooterTextSectionSlice {
	slice_type: 'footertextsection'
	primary: {
		column: number
		text: string
		text_class_name: string
	}
}

interface IProps {
	slice: IFooterTextSectionSlice
}

export const FooterTextSection = ({ slice: { primary } }: IProps) => {
	const baseClassName = 'KUI-Prismic-ColumnFooter-FooterTextSection'
	return (
		<div>
			<StyledP className={`${baseClassName}-Text`}>{primary.text}</StyledP>
		</div>
	)
}

const StyledP = styled.p`
	white-space: pre-wrap;
`
