import React, { useRef, SyntheticEvent } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import styled, { css } from 'styled-components'

import useLocalization from '../../../hooks/utils/use-localization'
import IconSearch from '../../icons/Search'
import { media } from '../../../utils/style-utils'

interface IProps {
	refine: (filter: string) => void
	isExpanded: boolean
	onClick: (event: React.MouseEvent<HTMLElement>) => void
	onEnterInInput: (
		event: SyntheticEvent | React.KeyboardEvent<HTMLElement>
	) => void
	value: string
}

const SearchBox = ({
	refine,
	isExpanded,
	value,
	onClick,
	onEnterInInput,
}: IProps) => {
	const { translate } = useLocalization()

	const inputRef = useRef<HTMLInputElement>(null)

	return (
		<LayoutWrapper
			role='search'
			isExpanded={isExpanded}
			onClick={(e) => {
				onClick(e)
				inputRef.current?.focus()
			}}
		>
			<IconSearch />
			<InputWrapper>
				<SInput
					ref={inputRef}
					type='search'
					tabIndex={isExpanded ? 0 : -1}
					isExpanded={isExpanded}
					value={value}
					onChange={(event) => refine(event.currentTarget.value)}
					placeholder={translate('search.searchPlaceholder')}
					onKeyDown={(e) => {
						if (e.nativeEvent.key === 'Enter') {
							onEnterInInput(e)
						}
					}}
				/>
				<ButtonText isExpanded={isExpanded}>
					{translate('search.search')}
				</ButtonText>
			</InputWrapper>
		</LayoutWrapper>
	)
}

const LayoutWrapper = styled.div<{ isExpanded: boolean }>`
	display: flex;
	border: 2px solid transparent;
	padding: 9px;
	transition: 420ms ease-in-out;

	${({ isExpanded, theme }) =>
		isExpanded &&
		css`
			border: 2px solid ${theme.colors.gold550};
			cursor: text;
		`}

	${media.tablet`
      padding-inline: 0px;
  `}
`

const InputWrapper = styled.div`
	padding-left: 8px;
	width: calc(100% - 24px);
`

const SInput = styled.input<{ isExpanded: boolean }>`
	${({ isExpanded }) => css`
		width: 0px;
		border: none;
		outline: none;
		padding: 0;
		font-size: 1rem;
		font-weight: 400;

		${isExpanded &&
		css`
			width: 100%;
			padding: 1px 2px;
		`}
	`}
`
const ButtonText = styled.span<{ isExpanded: boolean }>`
	${({ theme: { colors }, isExpanded }) => css`
		color: ${colors.gold550};
		font-weight: 700;
		${isExpanded &&
		css`
			display: none;
		`}
	`}
`
export default connectSearchBox(SearchBox)
