//A generic react context

import React, { createContext, useContext } from 'react'

import { IAnyPage } from '../../types/prismic/pages'

const PrismicRoutingContext = createContext<{
	pages: IAnyPage[]
	currentPageId: string
}>({ pages: [], currentPageId: '' })

export const usePrismicRoutingContext = (): any => {
	const context = useContext(PrismicRoutingContext)
	if (!context) {
		throw new Error(
			'usePrismic must be used within a PrismicRoutingContextProvider'
		)
	}
	if (!context.pages) {
		throw new Error('PrismicRoutingContextProvider not properly setup')
	}
	return context
}

export const PrismicRoutingContextProvider = ({
	currentPageId,
	pages,
	children,
}: {
	currentPageId: string
	pages: IAnyPage[]
	children?: React.ReactNode
}) => {
	return (
		<PrismicRoutingContext.Provider value={{ pages: pages, currentPageId }}>
			{children}
		</PrismicRoutingContext.Provider>
	)
}

export default PrismicRoutingContext
