import * as React from 'react'

import { KvikaSVGProps } from './types'

const Arrow = ({
	height = 18,
	fill = 'none',
	width = 21,
	rotate = 0,
	stroke = '#8E7148',
}: KvikaSVGProps) => (
	<svg
		width={width}
		height={height}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
		style={{ transform: `rotate(${rotate}deg)` }}
	>
		<path d='M21 9H1' stroke={stroke} strokeWidth={2} strokeMiterlimit={10} />
		<path
			d='M8 16 1 9l7-7'
			stroke={stroke}
			strokeWidth={2}
			strokeMiterlimit={10}
			strokeLinecap='square'
			strokeLinejoin='round'
		/>
	</svg>
)

export default Arrow
