import { motion } from 'framer-motion'
import React from 'react'
import styled, { css } from 'styled-components'

import useLocalization from '../../../hooks/utils/use-localization'
import { useOutsideClick } from '../../../hooks/utils/use-outside-click'
import { theme } from '../../../styles/globalStyles'
import { INavigation } from '../../../types/prismic/pages/navigation'
import { media } from '../../../utils/style-utils'
import SearchHeader from '../../Search/SearchHeader'
import HamburgerIcon from '../HamburgerIcon'

import { MainNavItem } from './MainNavItem'
import { NavigationButton } from './StaticNavigation'

export interface INewNavigationProps {
	headerNavigation: INavigation.IHeaderNavigationProps
}

export function HeaderNavigation({ headerNavigation }: INewNavigationProps) {
	const { translate } = useLocalization()
	const [menuOpen, setMenuOpen] = React.useState<boolean>(false)
	const [isSearchOpen, setSearchOpened] = React.useState<boolean>(false)
	const navRef = React.useRef(null)
	const [windowWidth, setWindowWidth] = React.useState<number>(0)
	const [isHamburgerMenu, setIsHamburgerMenu] = React.useState<boolean>(false)
	const threshold = Number(theme.breakpoints.desktopMinPlus.slice(0, -2))

	const closeNavigation = () => setMenuOpen(false)
	useOutsideClick(closeNavigation, navRef)

	const containerVariants = {
		closed: { right: '-380px' },
		open: {
			right: 0,
			display: 'flex',
			transition: {
				duration: 0.4,
				type: 'tween',
			},
		},
	}

	React.useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth
			setWindowWidth(width)
			setIsHamburgerMenu(width < threshold)
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [threshold])

	return (
		<StyledNavigation>
			<NavContainer
				variants={containerVariants}
				initial='closed'
				animate={menuOpen ? 'open' : 'closed'}
			>
				<LinkContainer isSearchOpen={isSearchOpen}>
					{headerNavigation.data.mainNavItems.map((item, index) => (
						<MainNavItem {...item} key={index} onLinkClick={closeNavigation} />
					))}
				</LinkContainer>
				<RelativeContainer>
					<SearchBar
						isSearchOpen={isSearchOpen}
						setSearchOpened={setSearchOpened}
					/>
				</RelativeContainer>
			</NavContainer>
			{isHamburgerMenu && (
				<NavigationButton
					className='mobileNavigationButton'
					onClick={() => {
						setMenuOpen(!menuOpen)
					}}
				>
					<HamburgerIcon
						strokeWidth={3}
						isOpen={menuOpen}
						width={17}
						height={15}
						color={theme.colors.gold550}
					/>
					{translate('navigation.menu')}
				</NavigationButton>
			)}
		</StyledNavigation>
	)
}

const RelativeContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	height: var(--spacer-base);

	${media.tablet`
		width: 100%;
	`}

	${media.phone`
		margin-top: var(--spacer-2xs);
	`};
`

const SearchBar = styled(SearchHeader)<{ isSearchOpen: boolean }>`
	position: absolute;
	width: 115px;
	max-width: 502px;
	right: 0;

	${media.tablet`
		right: unset;
	`}

	${({ isSearchOpen }) =>
		isSearchOpen &&
		css`
			width: calc(100vw - 520px);

			${media.tablet`
			width: 100%;
			left: 0;
		`}
		`}
`

const LinkContainer = styled.div<{ isSearchOpen: boolean }>`
	margin-right: 100px;
	display: flex;
	flex-direction: row;
	transition-delay: 350ms;
	${media.desktopSm`
	${({ isSearchOpen }) => css`
		${isSearchOpen &&
		css`
			visibility: hidden;
			transition-delay: 0ms;
		`};
	`}
	`}

	${media.tablet`
		flex-direction: column;
		visibility: visible;
		overflow-y: scroll;
		width: 100%;
	`}
`

const NavContainer = styled(motion.div)`
	display: flex;
	margin: 0;
	padding: 0 0 0 45px;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	${media.tablet`
		align-items: baseline;
		position: fixed;
		top: var(--spacer-base);
		bottom: 0;
		right: 0;
		max-width: 380px;
		width: 100%;
		padding: 16px 40px var(--spacer-md) 40px;
		padding: 0;
		padding-left: 40px;
		flex-direction: column-reverse;
		justify-content: flex-end;
		background-color: ${(props) => props.theme.colors.grey50};
		overflow-y: scroll;

		-ms-overflow-style: none; /* for Internet Explorer, Edge */
		scrollbar-width: none; /* for Firefox */
		&::-webkit-scrollbar {
			display: none; /* for Chrome, Safari, and Opera */
		}
	`}
`

const StyledNavigation = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.tablet`
		justify-content: flex-end;
	`}
`
