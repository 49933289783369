import * as React from 'react'

import { KvikaSVGProps } from '../icons/types'

const ShortRuler = ({
	height = '2px',
	width = 17,
	fill = '#DED2BE',
}: KvikaSVGProps) => {
	return (
		<svg
			width={`${width}px`}
			height={height}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d={`M0 2V0h${width}l-2 2H0Z`} fill={fill} />
		</svg>
	)
}

export default ShortRuler
