import React, { ReactNode, useEffect } from 'react'
import styled from 'styled-components'

import { useNotifications } from '../../hooks/utils/use-notifications'
import { INavigation } from '../../types/prismic/pages/navigation'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { media } from '../../utils/style-utils'
import Header from '../Header'
import { GridStyles } from '../Layout/Grid/Grid'
import ColumnFooter, {
	IPrismicColumnFooter,
} from '../PrismicColumnFooter/ColumnFooter'
import { createAlert } from '../../providers/notifications/NotificationsProvider'

type Props = {
	children?: ReactNode
	footer: IPrismicColumnFooter
	headerNavigation: INavigation.IHeaderNavigationProps
}

const Layout = ({ children, footer, headerNavigation }: Props) => {
	const { pages } = usePrismicRoutingContext()

	useNotifications()

	return (
		<SCPageWrapper>
			{headerNavigation && <Header headerNavigation={headerNavigation} />}
			{children}
			{footer && (
				<StyledColumnFooter
					linkResolver={(link) => resolvePrismicLink(link, pages)}
					footer={footer}
				/>
			)}
		</SCPageWrapper>
	)
}

export default Layout

const SCPageWrapper = styled.div`
	display: flex;
	min-height: 100vh;
	flex-direction: column;
`

const StyledColumnFooter = styled(ColumnFooter)`
	&&& {
		display: flex;
		padding-top: 80px;
		padding-bottom: 40px;
		background: ${({ theme }) => theme.colors.grey900};

		.KUI-Prismic-ColumnFooter-container {
			${media.desktopLg`
				width: 100%;
			`}
			${media.desktopSm`
				width: 100%;
			`}
			${GridStyles}
			grid-row-gap: 100px;
			color: #ba945e;
			font-size: 1rem;
			line-height: 1.5rem;

			${media.desktopMinPlus`
				grid-row-gap: 40px;
			`}

			${media.tablet`
				grid-row-gap: 60px;
			`}

			.KUI-Prismic-ColumnFooter-FooterIconLink {
				display: flex;
				align-items: center;
				color: ${({ theme }) => theme.colors.gold400};
			}

			.KUI-Prismic-ColumnFooter-FooterIconLink-Text {
				margin-left: 22px;
				font-style: normal;
				font-weight: 400;
				font-size: 1rem;
				line-height: 1.5rem;
			}

			.KUI-Prismic-ColumnFooter-FooterLink {
				font-weight: 400;
				font-size: 1rem;
				line-height: 1.5rem;
			}

			.KUI-Prismic-ColumnFooter-FooterLink {
				/* color: white; */
				text-decoration: none;

				:hover {
					text-decoration: underline;
				}
			}

			.KUI-Prismic-ColumnFooter-Column:nth-child(1) {
				grid-column: 1/ 6;

				${media.tablet`
					grid-column: 1 / -1
				`}
			}

			.KUI-Prismic-ColumnFooter-Column:nth-child(2) {
				grid-column: 7 / 10;

				${media.tablet`
					grid-column: 1/7;
				`}

				${media.phone`
					grid-column: 1 / -1
				`}
			}
			.KUI-Prismic-ColumnFooter-Column:nth-child(3) {
				grid-column: 10/ 13;

				${media.tablet`
					grid-column: 7 / 13;
				`}

				${media.phone`
					grid-column: 1 / -1;
				`}
			}

			.KUI-Prismic-ColumnFooter-Column:nth-child(4) {
				grid-row: 2;
				grid-column: 1 / 7;
				flex-direction: row;
				justify-content: center;

				${media.desktopMinPlus`
					justify-content: center;
					grid-row: 2;
					grid-column: 1/ -1;
				`}

				${media.tablet`
					grid-row: 3;
					grid-column: 1 / 7;
					flex-direction: column;
					justify-content: flex-start;
				`}

				${media.phone`
					grid-row: 4;
					gap: 8px;
					grid-column: 1 / -1;
				`}
			}
			.KUI-Prismic-ColumnFooter-Column:nth-child(5) {
				grid-row: 2;
				grid-column: 7 / 13;
				flex-direction: row;
				justify-content: center;

				${media.desktopMinPlus`
					justify-content: center;
					grid-row: 3;
					grid-column: 1/ -1;
				`}

				${media.tablet`
					grid-column: 7 / 13;
					flex-direction: column;
					justify-content: flex-start;
				`}

				${media.phone`
					grid-row: 5;
					gap: 18px;
					grid-column: 1 / -1;
				`}
			}

			.KUI-Prismic-ColumnFooter-FooterText {
				margin: 0;
				color: white;
			}

			.KUI-Prismic-ColumnFooter-FooterIcon-Link {
				margin-bottom: 20px;
			}
		}
	}
`
