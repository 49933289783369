import { css } from 'styled-components'

export const media = {
	phone: (...args) => {
		return css`
			@media screen and (max-width: 768px) {
				${css(...args)};
			}
		`
	},
	tabletMid: (...args) => {
		return css`
			@media screen and (max-width: 768px) {
				${css(...args)};
			}
		`
	},
	tablet: (...args) => {
		return css`
			@media screen and (max-width: 1023px) {
				${css(...args)};
			}
		`
	},
	desktopMinPlus: (...args) => {
		return css`
			@media screen and (max-width: 1024px) {
				${css(...args)};
			}
		`
	},
	desktopSm: (...args) => {
		return css`
			@media screen and (max-width: 1359px) {
				${css(...args)};
			}
		`
	},
	desktopLg: (...args) => {
		return css`
			@media screen and (min-width: 1360px) and (max-width: 1920px) {
				${css(...args)};
			}
		`
	},
	desktopXl: (...args) => {
		return css`
			@media screen and (min-width: 1921px) {
				${css(...args)};
			}
		`
	},
}

export const devices = {
	ios: (args) => {
		return css`
			@supports (-webkit-text-size-adjust: none) {
				${css(args)};
			}
		`
	},
	safariDesktop: (...args) => {
		return css`
			@media not all and (min-resolution: 0.001dpcm) {
				@supports (-webkit-appearance: none) and (display: flow-root) {
					${css(...args)};
				}
			}
		`
	},
}
