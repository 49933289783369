import { map } from 'lodash'

import { IPage } from '../../types/prismic/pages/generalPage'
import {
	IPrismicDocumentLink,
	IPrismicLink,
} from '../../types/prismic/baseTypes'
import { Language } from '../../types/Types'
import { IIndex } from '../../types/prismic/pages/indicies'
import { INavigation } from '../../types/prismic/pages/navigation'
import { IPersons } from '../../types/prismic/pages/persons'
import { IAnyPage, IAnyPageExclError } from '../../types/prismic/pages'

import PrismicClient from './Helpers'
import {
	getAllByMultipleTypes,
	getAllPages,
	getSingleByType,
} from './data-helpers'

export const PRISMIC_PAGE_SIZE = 100

export const getPathPrefix = (lang: string | undefined) => {
	switch (lang) {
		case 'en-gb':
			return '/en'
		default:
			return ''
	}
}

export const getLocale = (lang: string | undefined) => {
	switch (lang) {
		case 'en-gb':
			return 'en'
		default:
			return 'is'
	}
}

export const getPrismicLanguage = (lang: string) => {
	switch (lang) {
		case 'en':
			return 'en-gb'
		default:
			return undefined
	}
}

export const getLayoutData = async (
	previewDataRef: string | undefined,
	locale: string
) => {
	const client = PrismicClient()
	const [headerNavigation, footer] = await Promise.all([
		getSingleByType<INavigation.IHeaderNavigationProps>(
			'header_navigation',
			previewDataRef,
			locale
		),
		getSingleByType('columnfooter', previewDataRef, locale),
	])

	const mainNavItems = headerNavigation.data.mainNavItems

	if (mainNavItems) {
		mainNavItems.forEach((item) => (item.dropdown_groups = []))
		headerNavigation.data.body.forEach((slice) => {
			if (slice.slice_type === 'dropdown_navigation_group') {
				const headerNavigationItem = mainNavItems.find(
					(item) => item.dropdown_id === slice.primary.dropdown_id
				)

				if (headerNavigationItem) {
					headerNavigationItem.dropdown_groups.push(slice.items)
				}
			}
		})
	}

	return {
		headerNavigation: {
			...headerNavigation,
			data: { mainNavItems: mainNavItems ?? [] },
		},
		footer,
	}
}

export const getDefaultPageData = async (
	previewDataRef: string | undefined,
	locale: string
) => {
	const [layoutData, allPages] = await Promise.all([
		getLayoutData(previewDataRef, locale),
		getAllPages(previewDataRef),
	])

	const cleanedPages = map(allPages, (page) => ({
		lang: page.lang,
		type: page.type,
		uid: page.uid,
		id: page.id,
		alternate_languages: page.alternate_languages || null,
		data: {
			title: page.data?.title || null,
			person_type:
				('person_type' in page.data && page.data.person_type) || null,
			link_to_parent:
				('link_to_parent' in page.data && page.data.link_to_parent) || null,
		},
	}))

	return {
		headerNavigation: layoutData.headerNavigation,
		footer: layoutData.footer,
		allPages: cleanedPages,
	}
}

export const getOtherLocaleURL = (docId: string, pages: any[]) => {
	const page = pages.find((page) => page.id === docId)

	if (!page || page.alternate_languages.length === 0) return undefined

	const alternateLangDoc = pages.find(
		(altPage) => altPage.id === page.alternate_languages[0].id
	)
	if (!alternateLangDoc) return undefined
	else return resolveDocPath(alternateLangDoc, pages)
}

export const getParentPage = (
	page: IAnyPage,
	pages: IAnyPage[]
): IAnyPage | undefined => {
	if (!page) return undefined

	const findSinglePage = (pageType: string, lang: string) => {
		return pages.find((p) => p.type === pageType && p.lang === lang)
	}

	switch (page.type) {
		case 'page':
			return pages.find(
				(p) =>
					p.id ===
					(page as IPage.IProps | IPersons.IOverviewProps).data.link_to_parent
						?.id
			)
		case 'persons':
			return pages.find(
				(p) =>
					p.type === 'page' &&
					p.uid === (page.lang === 'en-gb' ? 'about-kvika' : 'um-kviku')
			)
		case 'newsarticle':
			return findSinglePage('newsoverview', page.lang)

		case 'financial_index':
			return findSinglePage('financial_index_overview', page.lang)

		case 'singleperson':
			return pages.find(
				(p) =>
					p.type === 'persons' &&
					p.lang === page.lang &&
					(p as IPersons.IOverviewProps).data.person_type ==
						(page as IPersons.ISingleProps).data.person_type
			)

		case 'frontpage':
			return undefined

		default:
			return findSinglePage('frontpage', page.lang)
	}
}

export const getLocallyFinancialIndexByUID = (
	uid: string,
	lang: Language,
	pages: IAnyPage[]
): IIndex.IFinancialIndexPage | undefined => {
	return pages.find(
		(p) => p.type === 'financial_index' && p.uid === uid && p.lang === lang
	) as IIndex.IFinancialIndexPage | undefined
}

export const resolvePrismicLink = (
	link: IPrismicLink,
	pages: IAnyPage[]
): string | undefined => {
	switch (link.link_type) {
		case 'Web':
			return link.url

		case 'Document':
			return link.uid ? resolvePrismicDocLink(link, pages) : undefined

		case 'Media':
			return link.url

		default:
			return ''
	}
}

export const resolvePrismicDocLink = (
	link: IPrismicDocumentLink,
	pages: IAnyPage[]
) => {
	const doc = pages.find((page) => page.id === link.id)

	return doc ? resolveDocPath(doc, pages) : undefined
}

export const resolveDocPath = (
	doc: IAnyPage,
	pages: any[],
	includeLanguagePrefix: boolean = true
): string | undefined => {
	if (!doc) return undefined

	const prefix = getPathPrefix(doc.lang)

	switch (doc.type) {
		case 'page':
		case 'singleperson':
			const link = `${
				includeLanguagePrefix ? prefix : ''
			}${resolveDynamicPagePath(doc as IPersons.ISingleProps, pages)}/`
			return link
			break
		default:
			return resolveHardCodedDocLink(doc)
	}
}

export const resolveDynamicPagePath = (
	doc: IPage.IProps | IPersons.ISingleProps,
	pages: IAnyPage[]
): string => {
	// * Recursively constructs the path to the page
	const parentPage = getParentPage(doc, pages)

	if (!parentPage) return `/${doc.uid}`

	if (parentPage.type === 'page') {
		return `${resolveDynamicPagePath(parentPage as IPage.IProps, pages)}/${
			doc.uid
		}`
	} else return `${resolveHardCodedDocLink(parentPage)}/${doc?.uid}`
}

const resolveHardCodedDocLink = (doc: IAnyPage) => {
	const switchOnLang = (isString, enString) => {
		const pathPrefix = getPathPrefix(doc.lang)
		return `${pathPrefix}${doc.lang === 'en-gb' ? enString : isString}`
	}

	switch (doc.type) {
		case 'newsoverview':
			return switchOnLang('/frettir', '/news')

		case 'persons':
			return switchOnLang(
				`/um-kviku/stjorn/${doc.uid}`,
				`/about-kvika/management/${doc.uid}`
			)

		case 'newsarticle':
			return switchOnLang(`/frettir/${doc.uid}`, `/news/${doc.uid}`)

		case 'financial_index_overview':
			return switchOnLang(`/visitolur`, '/indices/')

		case 'financial_index':
			return switchOnLang(`/visitolur/${doc.uid}`, `/indices/${doc.uid}`)

		case 'stock_exchange_news':
			return switchOnLang(`/frettir-ur-kaupholl`, '/stock-exchange-news')

		case 'investor_information':
			return switchOnLang(`/fjarfestaupplysingar`, '/investor-information')

		case 'contact':
			return switchOnLang('/hafa-samband', '/contact-us')

		case 'document':
			return `/documents/${doc.uid}`

		default:
			return `/`
	}
}

export const getAllSearchPages = async (
	locale?: string
): Promise<IAnyPageExclError[]> => {
	const typesForSearch = [
		'page',
		'newsoverview',
		'newsarticle',
		'financial_index_overview',
		'financial_index',
		'frontpage',
		'contact',
		'stock_exchange_news',
		'persons',
		'singleperson',
	]

	return getAllByMultipleTypes(typesForSearch, undefined, locale) as Promise<
		IAnyPageExclError[]
	>
}
