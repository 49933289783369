import * as React from 'react'

import { KvikaSVGProps } from './types'

const GenericFile = ({
	height = 18,
	width = 14,
	fill = '#8E7148',
}: KvikaSVGProps): JSX.Element => {
	return (
		<svg
			width={width}
			height={height}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0 0h9.249l.29.28.749.72h.02v.02l3.386 3.26.306.295V18H0V0Zm2 2h6.308v4H12v10H2V2Zm2 2H3v2h4V4H4ZM3 8h8v2H3V8Zm1 4H3v2h8v-2H4Z'
				fill={fill}
			/>
		</svg>
	)
}

export default GenericFile
