import React from 'react'
import styled from 'styled-components'

import Icons from '.'

interface IProps {
	fileExtension: string
}

const FileIcon = ({ fileExtension }: IProps) => {
	const Icon = Icons[fileExtension]

	if (!Icon) {
		return null
	}

	return (
		<StyledContainer>
			<Icon />
		</StyledContainer>
	)
}

export default FileIcon

const StyledContainer = styled.span`
	display: flex;
`
