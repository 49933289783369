import * as React from 'react'

import { KvikaSVGProps } from './types'

const ListItemBullet = ({
	height = 24,
	width = 24,
	fill = '#B89E74',
	style = {},
}: KvikaSVGProps) => (
	<svg
		width={width}
		height={height}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={style}
	>
		<path d='M7 13L15 13L13 15H7V13Z' fill={fill} />
		<path d='M7 12L16 12L15 10H7V12Z' fill={fill} />
	</svg>
)

export default ListItemBullet
