export const routingPaths = {
	is: {
		// locale & language
		news: 'frettir',
	},
	en: {
		// locale
		news: 'news',
	},
	'en-gb': {
		// language
		news: 'news',
	},
}
export const Locales = ['is', 'en']

export const NOTIFICATIONS_API_ROUTE = '/api/notifications'
