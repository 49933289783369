import Image from 'next/image'
import React from 'react'

export interface IPrismicImage {
	dimensions: {
		width: number
		height: number
	}
	alt?: null | string
	copyright?: null | string
	url: string
}

interface IProps {
	image: IPrismicImage
	className?: string
	layout?: 'fixed' | 'responsive' | 'fill' | 'intrinsic'
}

export const PrismicImage = ({
	image: { alt, url, dimensions },
	className,
	layout = 'responsive',
}: IProps) => {
	return (
		<Image
			layout={layout}
			className={className}
			src={url}
			alt={alt ?? ''}
			height={dimensions.height}
			width={dimensions.width}
		/>
	)
}
