import { map } from 'lodash'
import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { NOTIFICATIONS_API_ROUTE } from '../../constants/Constants'
import {
	createAlert,
	INotification,
} from '../../providers/notifications/NotificationsProvider'

type Notification = {
	primary: INotification
}

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000

const fetchNotifications = async (): Promise<INotification[]> => {
	return fetch(NOTIFICATIONS_API_ROUTE).then((data) => {
		if (data.status !== 200) throw 'Generic error'
		return data.text().then((text) => {
			return JSON.parse(text)
		})
	})
}

export const useFetchNotifications = (
	options?: { staleTime?: number; cacheTime?: number } | undefined
) => {
	return useQuery('notifications', () => fetchNotifications(), {
		staleTime: options?.staleTime || FIVE_MINUTES_IN_MS,
		cacheTime: options?.cacheTime || FIVE_MINUTES_IN_MS,
		notifyOnChangeProps: ['data'],
	})
}

// * This hook will fetch notifications and display them in the UI
export const useNotifications = () => {
	const { isSuccess, data: notifications } = useFetchNotifications()
	useEffect(() => {
		if (typeof window && isSuccess && Object.keys(notifications).length) {
			map(notifications, (notification: Notification) => {
				return createAlert(
					{
						title: notification.primary.title,
						message: notification.primary.message,
						type: notification.primary.type,
						uid: notification.primary.uid,
					},
					{
						duration:
							notification.primary.duration === 'infinite' ? Infinity : 5000,
					}
				)
			})
		}
	}, [notifications, isSuccess])
}
