import { motion } from 'framer-motion'
import Link from 'next/link'
import * as React from 'react'
import styled from 'styled-components'

import { useOutsideClick } from '../../../hooks/utils/use-outside-click'
import { INavigation } from '../../../types/prismic/pages/navigation'
import { usePrismicRoutingContext } from '../../../utils/prismic/Context'
import { resolvePrismicLink } from '../../../utils/prismic/RouteHelpers'
import { media } from '../../../utils/style-utils'
import { CollapsableArrow } from '../../icons'

import { NavigationLink } from './NavigationLink'

const Transition = {
	duration: 0.4,
	type: 'tween',
}

export interface IMainNavItemProps extends INavigation.IMainNavigationItem {
	onLinkClick: () => void
}

export function MainNavItem({
	dropdown_id,
	dropdown_groups,
	is_dropdown,
	link,
	link_label,
	onLinkClick,
}: IMainNavItemProps) {
	const { pages } = usePrismicRoutingContext()
	const linkUrl = resolvePrismicLink(link, pages)

	const [isExpanded, setExpanded] = React.useState(false)

	const ref = React.useRef(null)
	useOutsideClick(() => setExpanded(false), ref)

	const dropdownHoverVariants = {
		rest: { top: '-1000%', transition: { ...Transition, delay: 0.15 } },
		hover: {
			top: 'var(--spacer-base)',
			transition: { ...Transition },
		},
	}

	return (
		<HorizontalNavItem
			ref={ref}
			transition={{ delay: 1 }}
			initial='rest'
			onClick={() => setExpanded(!isExpanded)}
			whileHover='hover'
			animate={isExpanded ? 'hover' : 'rest'}
		>
			{linkUrl ? (
				<Link href={linkUrl} passHref legacyBehavior>
					<NavigationAnchor onClick={onLinkClick}>
						<span>{link_label}</span>
						{is_dropdown && <CollapsableArrow rotate={180} />}
					</NavigationAnchor>
				</Link>
			) : (
				<NavigationSpan>
					<span>{link_label}</span>
					{is_dropdown && <CollapsableArrow rotate={180} />}
				</NavigationSpan>
			)}
			{is_dropdown && dropdown_groups && (
				<DropdownCard variants={dropdownHoverVariants}>
					{dropdown_groups.map((dropDownLinks, groupIndex) => (
						<DropdownGroup key={groupIndex}>
							{dropDownLinks.map((link, linkIndex) => (
								<NavigationLink
									key={linkIndex}
									linkItem={link}
									pages={pages}
									onClick={onLinkClick}
								/>
							))}
						</DropdownGroup>
					))}
				</DropdownCard>
			)}
		</HorizontalNavItem>
	)
}

const DropdownCard = styled(motion.div)`
	display: flex;
	padding: 40px 160px;
	position: absolute;
	top: var(--spacer-base);
	right: 0;
	left: 0;
	gap: 38px;
	z-index: -1;
	background-color: ${(props) => props.theme.colors.grey50};
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

	${media.tablet`
		position: static;
		top: auto;
		display: flex;
		padding: 0;
		flex-direction: column;
		box-shadow: none;
	`}
`

const DropdownGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 268px;

	gap: 12px;
	${media.tablet`
		padding: 0;
	`}
`

const HorizontalNavItem = styled(motion.div)`
	height: var(--spacer-base);
	padding: 0 20px;

	${media.tablet`
		height: auto;
		margin-bottom: 40px;
		padding: 0;

		&:last-child {
			margin-bottom: 0;
		}
	`}
`
const NavigationAnchor = styled.a`
	display: flex;
	height: 100%;
	font-weight: 500;
	align-items: center;

	&:hover {
		text-decoration: none;
		color: ${(props) => props.theme.colors.gold400};
	}

	svg {
		margin-left: 10px;
	}
`

const NavigationSpan = styled.p`
	display: flex;
	height: 100%;
	align-items: center;
	margin: 0;
	font-weight: 500;
	color: ${(props) => props.theme.colors.gold550};
	cursor: pointer;
	transition: color 0.25s ease;

	&:hover {
		color: ${(props) => props.theme.colors.gold400};
	}

	svg {
		margin-left: 10px;
	}

	${media.tablet`
		display: none;
	`}
`
