import React from 'react'
import styled from 'styled-components'
import { groupBy, map } from 'lodash'

import FooterSliceZone, { IFooterSlice } from './FooterSliceZone'

export interface IPrismicColumnFooter {
	lang: string
	first_publication_date: string
	last_publication_date: string
	href?: string
	type: 'columnfooter'
	uid?: string
	data: {
		body: IFooterSlice[]
	}
}

interface IProps {
	className?: string
	linkResolver?: (link: any) => string | undefined
	footer: IPrismicColumnFooter
}

const ColumnFooter: React.FC<IProps> = ({
	className,
	linkResolver,
	footer: {
		data: { body },
	},
}) => {
	const sortedColumns = groupBy(body, 'primary.column')
	const baseClassName = 'KUI-Prismic-ColumnFooter'
	return (
		<footer className={`${className} ${baseClassName}-footer`}>
			<div className={`${baseClassName}-container`}>
				{map(sortedColumns, (slices, index) => {
					return (
						<SCFooterColumn
							key={`column-${index}`}
							className='KUI-Prismic-ColumnFooter-Column'
						>
							<FooterSliceZone linkResolver={linkResolver} slices={slices} />
						</SCFooterColumn>
					)
				})}
			</div>
		</footer>
	)
}
export default ColumnFooter

const SCFooterColumn = styled.div`
	display: flex;
	align-items: baseline;
	flex-direction: column;
	gap: 16px;
`
