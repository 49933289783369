import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { SearchState } from 'react-instantsearch-core'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { useRouter } from 'next/router'

import { algoliaIndexName, searchClient } from '../../../utils/algoliaHelpers'
import { media } from '../../../utils/style-utils'
import { useOutsideClick } from '../../../hooks/utils/use-outside-click'

import SearchBox from './SearchBox'
import Hits from './Hits'

interface IProps {
    isSearchOpen: boolean,
    setSearchOpened: (val: boolean) => void;
    className?: string
}

const SearchHeader: React.FC<IProps> = ({ isSearchOpen, setSearchOpened, className }) => {
    const [searchState, setSearchState] = useState<SearchState>()
    const queryLength = searchState?.query?.length ?? 0

    const router = useRouter()
    const resetSearch = () => {
        setSearchState(undefined)
        setSearchOpened(false)
    }

    const containerRef = useRef(null)

    useOutsideClick(() => resetSearch(), containerRef)

    const indexName = `${algoliaIndexName}-${router.locale ?? 'is'}`

    return (
        <InstantSearch
            onSearchStateChange={(state) => setSearchState(state)}
            indexName={indexName}
            searchClient={searchClient(indexName)}
            stalledSearchDelay={500}
        >
            <Configure hitsPerPage={6} />
            <SearchContainer className={className} isExpanded={isSearchOpen} ref={containerRef} >
                <SearchBox
                    isExpanded={isSearchOpen}
                    onClick={() => {
                        setSearchOpened(true)
                    }}
                    value={searchState?.query ?? ''}
                    onEnterInInput={(event: any) => {
                        resetSearch()
                    }}
                />
                {queryLength > 0 && (
                    <Hits
                        //@ts-ignore
                        onHitClick={() => {
                            resetSearch()
                        }}
                        isExpanded={isSearchOpen}
                        onShowAllResultsClick={(event: any) => {
                            resetSearch()
                        }}
                    />
                )}
            </SearchContainer>
        </InstantSearch>
    )
}

export default SearchHeader

const SearchContainer = styled.div<{ isExpanded: boolean }>`
    cursor: pointer;
    transition: 420ms ease-in-out;

    ${({ isExpanded }) => isExpanded && css`
        position: absolute;
        z-index: 100;
        background-color:white;
        cursor: text;

    `}
`
