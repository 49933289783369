import { get } from 'lodash'
import { RichTextBlock } from 'prismic-reactjs'
import * as React from 'react'
import * as ReactHotToast from 'react-hot-toast'
import styled from 'styled-components'

import Alert from '../../components/Alert/Alert'
import { media } from '../../utils/style-utils'

export interface INotification {
	title: string
	message: string | RichTextBlock[]
	type?: 'alert' | 'notification' | 'default'
	uid?: string
	duration?: 'infinite' | '5 seconds'
}

const DEFAULT_OPTIONS = {
	duration: Infinity,
}

const toastOptions = {
	...DEFAULT_OPTIONS,
}

const containerStyle = {
	width: '100%',
	inset: 0,
	marginTop: 80,
	zIndex: 499, // * navbar is 500
}

// * This is the function you want to use to create toasts
export const createAlert = (
	notification: INotification,
	options?: ReactHotToast.ToastOptions
) => {
	const toastOptions = {
		...DEFAULT_OPTIONS,
		...options,
	}
	return ReactHotToast.toast(notification as any, toastOptions) // React Hot Toast doesn't support type `INotification`
}

// * The Toast provider
export default function Toaster() {
	return (
		<ReactHotToast.Toaster
			toastOptions={toastOptions}
			containerStyle={containerStyle}
		>
			{(t) => {
				const title = get(t, 'message.title', '')
				const message = get(t, 'message.message', '')
				const type = get(t, 'message.type', 'default')
				const uid = get(t, 'message.uid', '')

				const previousNotifications = JSON.parse(
					// * get previous notifications from sessionStorage
					sessionStorage.getItem('seenNotifications') || '[]'
				)

				const onClose = () => {
					ReactHotToast.toast.dismiss(t.id)

					if (uid) {
						// * This prevents the user from seeing the same notification twice during the same session
						const nextNotifications = [...previousNotifications, uid]
						sessionStorage.setItem(
							'seenNotifications',
							JSON.stringify(nextNotifications)
						)
					}
				}

				if (previousNotifications.includes(uid)) {
					// * Don't return anything – the notification has already been seen
					return <></>
				}

				return (
					<Alert
						visible={t.visible}
						title={title}
						message={message}
						t={t}
						type={type}
						onClose={onClose}
					/>
				)
			}}
		</ReactHotToast.Toaster>
	)
}
