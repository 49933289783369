import React from 'react'
import { motion, Transition } from 'framer-motion'

interface Props {
	width?: number
	height?: number

	isOpen?: boolean
	color?: string
	strokeWidth?: string | number
	transition?: Transition
	lineProps?: any
}

const VARIANTS = {
	TOP: {
		closed: {
			rotate: 0,
			translateY: 0,
		},
		opened: {
			rotate: 45,
			translateY: 2,
		},
	},
	CENTER: {
		closed: {
			opacity: 1,
		},
		opened: {
			opacity: 0,
		},
	},
	BOTTOM: {
		closed: {
			rotate: 0,
			translateY: 0,
		},
		opened: {
			rotate: -45,
			translateY: -2,
		},
	},
}

const UNIT_HEIGHT = 4

export default function HamburgerIcon({
	isOpen = false,
	width = 24,
	height = 24,
	strokeWidth = 1,
	color = '#000',
	transition,
	lineProps = null,
	...props
}: Props) {
	const variant = isOpen ? 'opened' : 'closed'
	lineProps = {
		stroke: color,
		strokeWidth: strokeWidth as number,
		vectorEffect: 'non-scaling-stroke',
		initial: 'closed',
		animate: variant,
		transition,
		...lineProps,
	}
	const unitWidth = (UNIT_HEIGHT * (width as number)) / (height as number)

	return (
		<motion.svg
			viewBox={`0 0 ${unitWidth} ${UNIT_HEIGHT}`}
			overflow='visible'
			preserveAspectRatio='none'
			width={width}
			height={height}
			{...props}
		>
			<motion.line
				x1='0'
				x2={unitWidth}
				y1='0'
				y2='0'
				variants={VARIANTS.TOP}
				{...lineProps}
			/>
			<motion.line
				x1='0'
				x2={unitWidth * 0.8}
				y1='2'
				y2='2'
				variants={VARIANTS.CENTER}
				{...lineProps}
			/>
			<motion.line
				x1='0'
				x2={unitWidth}
				y1='4'
				y2='4'
				variants={VARIANTS.BOTTOM}
				{...lineProps}
			/>
		</motion.svg>
	)
}
