import Link from 'next/link';
import * as React from 'react';
import styled from 'styled-components';

import { IAnyPage } from '../../../types/prismic/pages';
import { INavigation } from '../../../types/prismic/pages/navigation';
import { resolvePrismicLink } from '../../../utils/prismic/RouteHelpers';
import { media } from '../../../utils/style-utils';



export interface INavigationLinkProps {
    linkItem: INavigation.IHeaderNavigationDropdownItem,
    pages: IAnyPage[],
    onClick: () => void
}

export const NavigationLink = ({ linkItem, pages, onClick }: INavigationLinkProps) => {

    const dropdownLinkUrl = resolvePrismicLink(
        linkItem.link,
        pages
    )

    return (
        <Link href={dropdownLinkUrl ?? ''} passHref legacyBehavior>
            <DropdownNavigationAnchorItem
                isHighlight={
                    linkItem.is_highlight
                }
                onClick={onClick}
            >
                {linkItem.link_label}
            </DropdownNavigationAnchorItem>
        </Link>
    );
}

const DropdownNavigationAnchorItem = styled.a<{ isHighlight?: boolean }>`
    font-weight: ${(props) => (props.isHighlight ? '500' : '400')};
    color: ${(props) =>
        props.isHighlight
            ? props.theme.colors.gold550
            : props.theme.colors.grey800};

    &:hover {
        text-decoration: none;
    }

    ${media.tablet`
        font-size: ${(props) => (props.isHighlight ? '1rem' : '0.875rem')};
    `}
`




