import * as React from 'react'

import { KvikaSVGProps } from './types'

const Lock = ({
	size = '1.3em',
	fill = 'currentColor',
}: KvikaSVGProps): JSX.Element => {
	return (
		<svg
			width={size}
			height={size}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3 7V6a6 6 0 1 1 12 0v1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h2Zm13 2H2v10h14V9Zm-8 5.732a2 2 0 1 1 2 0V17H8v-2.268ZM5 7h8V6a4 4 0 1 0-8 0v1Z'
				fill={fill}
			/>
		</svg>
	)
}

export default Lock
