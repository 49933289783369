import React from 'react'
import DOMPurify from 'isomorphic-dompurify'
import { get } from 'lodash'
import styled from 'styled-components'

import { Grid } from '../Layout/Grid'
import { media } from '../../utils/style-utils'
import { IBaseSlice } from '../../types/prismic/baseTypes'

import { StyledTableContainer } from './styles'

export interface IHtmlTableSlice extends IBaseSlice {
	primary: {
		html_table: {
			text: string
		}[]
	}
	slice_type: 'preformatted_html_table'
}

export interface IHtmlTable {
	sliceData: IHtmlTableSlice
}

// * Sanitize markup, exclusively permits <table> innerHTML markup
export const sanitizePreformattedHtmlTable = (html: string) => {
	const sanitized = DOMPurify.sanitize(html, {
		ALLOWED_TAGS: [
			'table',
			'tr',
			'th',
			'td',
			'thead',
			'tbody',
			'tfoot',
			'caption',
			'colgroup',
			'col',
			'a',
		],
		ALLOWED_ATTR: ['cellpadding', 'cellspacing', 'border', 'width', 'href'],
	})
	return sanitized
}

const HtmlTable = ({ sliceData }: IHtmlTable) => {
	const tableMarkup = get(sliceData, 'primary.html_table[0].text', '')
	const sanitizedTableMarkup = sanitizePreformattedHtmlTable(tableMarkup)

	return (
		<GridContainer>
			<StyledContainer>
				<StyledTableContainer
					dangerouslySetInnerHTML={{ __html: sanitizedTableMarkup }}
				/>
			</StyledContainer>
		</GridContainer>
	)
}

const StyledContainer = styled.div`
	grid-column: 1 / -1;
`

const GridContainer = styled(Grid)`
	${media.tablet`
		overflow-x: auto;
	`}
`

export default HtmlTable
