// const window: Window
// declare const global: UnknownObject;

export enum SegmentTrackingId {
	LanguageChangedTo = 'Language Changed To XX',
	OnlineBankClick = 'Online bank navigation button clicked',
	// LoginStarted = 'User Login Inititated',
	// LoginCompleted = 'User Login Completed',
	// LoginFailed = 'User Login Failed',
	// Logout = 'User Logged Out',
	// PersonalInformation = 'User Confirm Information',
	// StartSurveyAml = 'User Survey AML',
	// StartSurveyAppr = 'User Survey Appropriateness',
	// Categorization = 'Choose User Category',
	// Agreement = 'User Asset Creation Agreement',
	// TermsApprove = 'Approve Terms And Information',
	// DocumentSigning = 'Document Signing',
	// OnboardingStarted = ' User Onboarding Initiated',
	// OnboardingCompleted = 'User Onboarding Completed',
	// BuyFundsStarted = 'User Buy Funds Initiated',
	// SelectFundSuccess = 'Select Fund Success',
	// InappropriateFundModal = 'Inappropriate Fund Modal was triggered',
	// RelinquishRightsModal = 'Relinquish Rights Modal was triggered',
}

export enum SegmentPageId {
	Home = 'Home',
}

export type AnalyticsEvent = {
	event: SegmentTrackingId | SegmentPageId
	properties?: Record<string, unknown>
}

export const trackPage = (
	url: string,
	properties?: Record<string, unknown>
): void => {
	// ! remember to pull latest env if this is giving you an issue
	if (typeof window && window.analytics) window.analytics.page(url, properties)
}

export const trackEvent = (analyticsEvent: AnalyticsEvent): void => {
	if (global)
		global.analytics.track(analyticsEvent.event, analyticsEvent.properties)
}

export const identifyUser = (
	guid: string,
	traits?: Record<string, unknown>
): void => {
	global.analytics.identify(guid, traits)
}
