import * as React from 'react'

import { KvikaSVGProps } from './types'

const PDF = ({ height = '20px', fill = 'currentColor' }: KvikaSVGProps): JSX.Element => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21.1 22.6' fill={fill} height={height}>
			<title>{'pdf'}</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_13' data-name='Layer 13'>
					<g id='Layer_2-2' data-name='Layer 2'>
						<g id='Layer_1-2' data-name='Layer 1-2'>
							<g id='Layer_1-3' data-name='Layer 1-3'>
								<g id='Layer_2-2-2' data-name='Layer 2-2'>
									<g id='Layer_1-2-2' data-name='Layer 1-2-2'>
										<g id='Layer_3' data-name='Layer 3'>
											<path
												className='cls-1'
												d='M16 19.2v2.1H1.3v-20h11.4v3.3H16v3.9h1.3V3.9L13.4 0H0v22.6h17.3v-3.4H16z'
											/>
											<path
												className='cls-1'
												d='M7.6 12.5c-.3 0-.4 0-.4.1v1.5h.4c.6 0 1-.3 1-.8s-.4-.8-1-.8ZM12.1 12.5c-.3 0-.4 0-.5.1v3.3h.4a1.526 1.526 0 0 0 1.7-1.5v-.3a1.456 1.456 0 0 0-1.297-1.6H12.4Z'
											/>
											<path
												className='cls-1'
												d='M3.2 9.3v9h17.9v-9Zm6 5.1a2.155 2.155 0 0 1-1.7.5h-.3v1.8H6v-5.1a7.569 7.569 0 0 1 1.5-.1 2.604 2.604 0 0 1 1.5.4 1.743 1.743 0 0 1 .6 1.2 1.31 1.31 0 0 1-.4 1.3Zm4.9 1.7a3.353 3.353 0 0 1-2.4.6c-.6 0-1-.1-1.3-.1v-5a7.569 7.569 0 0 1 1.5-.1 3.223 3.223 0 0 1 2.1.6 2.094 2.094 0 0 1 .8 1.9 2.008 2.008 0 0 1-.7 2.1Zm4.7-3.6h-2v1.2h1.9v1h-1.9v2h-1.2v-5.1h3.1Z'
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default PDF
