import * as prismic from '@prismicio/client'
import { RichTextBlock } from 'prismic-reactjs'

export const isPrismicRichTextValid = (
	prismicRichText: RichTextBlock[]
): Boolean => {
	if (prismicRichText === null || prismicRichText === undefined) {
		return false
	} else if (
		prismicRichText.length < 0 ||
		prismicRichText[0].text === undefined ||
		prismicRichText[0].text?.length > 0
	) {
		return false
	} else {
		return true
	}
}

export const isPrismicStringValid = (prismicText: string): Boolean => {
	if (prismicText === null || prismicText === undefined) {
		return false
	} else if (prismicText.length < 0) {
		return false
	} else {
		return true
	}
}

// 2022 new Client method to query documents from the Prismic repo
const PrismicClient = (ref?: any) => {
	const endpoint = prismic.getRepositoryEndpoint(
		process.env.PRISMIC_TEMPLATE_NAME || 'kvika'
	)
	const client = prismic.createClient(endpoint, {
		accessToken: process.env.PRISMIC_ACCESS_TOKEN || undefined,
		ref,
	})

	return client
}

export default PrismicClient
