export const canUseDOM = (): boolean => {
	if (
		typeof window === 'undefined' ||
		!window.document ||
		!window.document.createElement
	) {
		return false
	}
	return true
}
