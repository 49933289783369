import React, { SyntheticEvent } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { Hit, HitsProvided } from 'react-instantsearch-core'
import styled, { css } from 'styled-components'

import { IHitProps } from '../../../types/Search'
import SearchHits from '../SearchOverview/SearchHits'
import useLocalization from '../../../hooks/utils/use-localization'

interface IProps extends HitsProvided<IHitProps> {
  onHitClick: (event: React.MouseEvent<HTMLElement>) => void
  onShowAllResultsClick: (event: SyntheticEvent | React.MouseEvent<HTMLButtonElement>) => void
}
const Hits = ({ hits, onHitClick, onShowAllResultsClick }: IProps) => {

  const { translate } = useLocalization()

  return hits.length > 0 ? (
    <Modal>
      <SearchHits hits={hits} onHitClick={onHitClick} />
      {/* <ButtonWrapper>
        <SCSeeAllResults onClick={onShowAllResultsClick}>
          {translate('search.seeAllResults')}
        </SCSeeAllResults>
      </ButtonWrapper> */}
    </Modal>
  ) : null
}
//@ts-ignore
export default connectHits(Hits)

const SCSeeAllResults = styled.a`
    background-color: white;
    border: none;
    color: ${(p) => p.theme.colors.gold550};
    font-size: 1rem;
    font-weight: 400;
    margin: 12px;
    cursor: pointer;
`

const Modal = styled.div`
    width: 100%;
    background-color: white;
    position: absolute;
    margin-top: 8px;
    border-radius: 2px;
    border: 2px solid ${(p) => p.theme.colors.gold550};
`

const ButtonWrapper = styled.div`
      display: flex;
      justify-content: center;

`
