import React from 'react'
import { PrismicLink } from '@prismicio/react'
import Link from 'next/link'

import { IPrismicImage, PrismicImage } from './PrismicImage'
import { IPrismicLink } from './FooterLink'

export interface IFooterIconSlice {
	slice_type: 'footericon'
	primary: {
		column: number
		link: IPrismicLink
		icon: IPrismicImage
	}
}

export interface IProps {
	slice: IFooterIconSlice
	linkResolver?: (link: any) => string | undefined
}

export const FooterIcon = ({
	slice: {
		primary: { link, icon },
	},
	linkResolver,
}: IProps) => {
	const baseClassName = 'KUI-Prismic-ColumnFooter-FooterIcon'
	return (
		<PrismicLink
			// @ts-ignore
			externalComponent={(props) => (
				<Link className={`${baseClassName}-Link`} {...props} />
			)}
			// @ts-ignore
			internalComponent={(props) => (
				<Link className={`${baseClassName}-Link`} {...props} />
			)}
			field={link}
			linkResolver={linkResolver}
		>
			<PrismicImage
				layout='fixed'
				image={icon}
				className={`${baseClassName}-Icon`}
			/>
		</PrismicLink>
	)
}
