import Button, { ButtonBackgroundType, IconPlacement } from '@kvika/button'
import { Close } from '@kvika/icons'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import { Toast } from 'react-hot-toast'
import styled, { css, keyframes } from 'styled-components'

import useLocalization from '../../hooks/utils/use-localization'
import { FullBleedGrid } from '../Layout/Grid'
import HtmlSerializer from '../HtmlSerializer/htmlSerializer'
import { media } from '../../utils/style-utils'

interface Props {
	title?: string
	message?: string | RichTextBlock[]
	type?: string
	className?: string
	visible: boolean
	t: Toast
	onClose: () => void
}

const Alert: React.FC<Props> = ({
	title,
	message,
	type = 'default',
	className,
	visible,
	onClose,
	t,
}) => {
	const { translate } = useLocalization()

	return (
		<AlertBar type={type} className={className} visible={visible}>
			<AlertWrapper>
				<AlertText>
					<Title>{title}</Title>
					<Message>
						{typeof message === 'object' ? (
							<RichText
								render={message}
								htmlSerializer={(...args) => {
									return HtmlSerializer(...args, {
										enableFileIconAnchors: false,
										enableMediaLinks: false,
									})
								}}
							/>
						) : (
							message
						)}
					</Message>
				</AlertText>
				{t.duration === Infinity && (
					<CloseButton
						alertType={type}
						onClick={() => onClose()}
						backgroundType={ButtonBackgroundType.Text}
						icon={<Close />}
						iconPlacement={IconPlacement.RIGHT}
					>
						{translate('general.close')}
					</CloseButton>
				)}
			</AlertWrapper>
		</AlertBar>
	)
}

export default Alert

const enterAnimation = keyframes`
  0% {transform: translate3d(0,${1 * -200}%,0); opacity:.5;}
  100% {transform: translate3d(0,0,0); opacity:1;}
`

const exitAnimation = keyframes`
  0% {transform: translate3d(0,0,-1px); opacity:1;}
  100% {transform: translate3d(0,${1 * -150}%,-1px); opacity:0;}
`

const AlertBar = styled(FullBleedGrid)<{ type?: string; visible: boolean }>`
	width: 100%;
	height: 120px;
	background: ${(p) => p.theme.colors.gold100};
	border-bottom: 1px solid ${(p) => p.theme.colors.gold200};
	box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.1);

	${(p) => {
		return (
			p.type === 'alert' &&
			css`
				background: ${p.theme.colors.warning100};
				border-bottom: 0;
				/* background: yellow; */
			`
		)
	}}

	animation: ${(p) =>
		p.visible
			? css`
					${enterAnimation} 0.35s cubic-bezier(.21,1.02,.73,1) forwards
			  `
			: css`
					${exitAnimation} 0.8s forwards cubic-bezier(.06,.71,.55,1)
			  `};

	${media.phone`
		height: 150px;
		padding-top: 15px;
	`}
`

const AlertWrapper = styled.div`
	grid-column: 2 / -2;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${media.phone`
		flex-direction: column;
		align-items: flex-start;

		overflow-wrap: wrap;
	`}
`

const AlertText = styled.div`
	display: flex;
	align-items: center;
	gap: var(--spacer-xs);
	
	${media.tablet`
		flex-direction: column;
		align-items: flex-start;

		gap: 2px;
		overflow-wrap: wrap;
	`}
`

const Title = styled.h2`
	display: inline-block;
`
const Message = styled.span`
	word-wrap: wrap;
	white-space: pre-line;
`

const CloseButton = styled(Button)<{ alertType: string }>`
	${(p) =>
		p.alertType !== 'alert' &&
		css`
			color: ${p.theme.colors.grey800};
		`}
`
