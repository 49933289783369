import { FC } from 'react'

import Arrow from './Arrow'
import CollapsableArrow from './CollapsableArrow'
import GenericFile from './GenericFile'
import LineBorder from './LineBorder'
import Lock from './Lock'
import PDF from './PDF'
import SiteLogo from './SiteLogo'
import Globe from './Globe'
import ExtendArrow from './ExtendArrow'
import Union from './Union'
import { KvikaSVGProps } from './types'

const Icons: Record<string, FC<KvikaSVGProps>> = {
	PDF,
	CollapsableArrow,
	GenericFile,
	Lock,
	SiteLogo,
	LineBorder,
	ExtendArrow,
	Union,
}

export {
	Globe,
	PDF,
	GenericFile,
	Lock,
	CollapsableArrow,
	ExtendArrow,
	SiteLogo,
	Arrow,
	LineBorder,
}

export default Icons
