import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import Icons from '../icons'
import FileIcon from '../icons/FileIcon'

interface IProps {
	children: ReactNode
	href: string
	showIcon?: boolean
}

// * This component renders a link with an appended file type icon
const FileIconAnchor = ({ children, href, showIcon = true }: IProps) => {
	const fileExtension = (href?.split('.')?.pop() || '').toUpperCase()
	const Icon = Icons[fileExtension]

	return (
		<Anchor
			className='h2'
			href={href}
			style={{
				display: 'inline-flex',
				alignItems: 'center',
				gap: 16,
			}}
		>
			<AnchorContent showIcon={!!Icon && showIcon}>
				{showIcon && (
					<IconWrapper>
						<FileIcon fileExtension={fileExtension} />
					</IconWrapper>
				)}
				{children}
			</AnchorContent>
		</Anchor>
	)
}

export default FileIconAnchor

const Anchor = styled(Link)`
	display: inline-flex;
	align-items: baseline;
	gap: 16px;
`

const AnchorContent = styled.span<{ showIcon?: boolean }>`
	display: flex;
	gap: 8px;
	position: relative;
	margin-left: ${({ showIcon }) => (showIcon ? 'var(--spacer-sm)' : '0')};
`

const IconWrapper = styled.span`
	position: absolute;
	top: 2px;
	/* left: -24px; */
	left: calc(var(--spacer-sm) * -1);
`
