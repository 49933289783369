import { KvikaSVGProps } from "./types"

const Union = ({
    width = 15, 
    height = 15, 
    fill = 'none'
}: KvikaSVGProps) => (
    <svg 
        width={width} 
        height={height}
        viewBox="0 0 15 15" 
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14 0H7V2H11.5858L4.29289 9.29289L5.70711 10.7071L13 3.41421V8H15V1C15 0.447715 14.5523 0 14 0Z" 
            fill="#907140"
        />
        <path 
            d="M10 13V7.99998L12 5.99998V14C12 14.5523 11.5523 15 11 15H1C0.447715 15 0 14.5523 0 14V3.99998C0 3.44769 0.447715 2.99998 1 2.99998H9L7 4.99998H2V13H10Z" 
            fill="#907140"
        />
    </svg>

)

export default Union