import React from 'react'

export interface IFooterParagraphSlice {
	slice_type: 'footertext'
	primary: {
		column: number
		text: string
	}
}

interface IProps {
	slice: IFooterParagraphSlice
}

export const FooterText = ({ slice: { primary } }: IProps) => {
	return <p className={`KUI-Prismic-ColumnFooter-FooterText`}>{primary.text}</p>
}
