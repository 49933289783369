import { KvikaSVGProps } from './types'

const LineBorder = ({ height = 1, fill = 'none', width = 100 }: KvikaSVGProps) => (
	<svg width={width} height={height} viewBox='0 0 100 1' fill={fill} xmlns='http://www.w3.org/2000/svg'>
		<line x1='0.5' y1='0.5' x2='8.5' y2='0.5' stroke='#B89E74' />
		<line x1='12.5' y1='0.5' x2='87.5' y2='0.5' stroke='#B89E74' />
		<line x1='91.5' y1='0.5' x2='99.5' y2='0.5' stroke='#B89E74' />
	</svg>
)

export default LineBorder
