import { AppProps } from 'next/dist/shared/lib/router/router'
import Head from 'next/head'
import React, { useEffect } from 'react'
import { Analytics } from '@vercel/analytics/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from 'styled-components'
import { useRouter } from 'next/router'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import * as snippet from '@segment/snippet'
import dayjs from 'dayjs'
import Script from 'next/script'

import '../../public/fonts.css'

require('dayjs/locale/is')

import Layout from '../components/AppLayout'
import GlobalStyle, { theme } from '../styles/globalStyles'
import { PrismicRoutingContextProvider } from '../utils/prismic/Context'
import { trackPage } from '../utils/analytics'
import Toaster from '../providers/notifications/NotificationsProvider'
import SegmentScript from '../components/SegmentScript/SegmentScript'
import CookieHubLoader from '../components/CookieHub/CookieHubLoader'

const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY || ''

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter()
	const [queryClient] = React.useState(() => new QueryClient())

	useEffect(() => {
		const trackPageRouteEvent = (url) => {
			trackPage(url)
		}

		router.events.on('routeChangeComplete', trackPageRouteEvent)
		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			router.events.off('routeChangeStart', trackPageRouteEvent)
		}
	}, [router.events])

	const locale = router?.locale || 'is'
	dayjs.locale(locale)

	const key = `/${router.locale}${router.asPath}` // This key makes sure that useState states are reset when the locale changes

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<ReactQueryDevtools />
				<ThemeProvider theme={theme}>
					<GlobalStyle />
					<Head>
						<link
							rel='apple-touch-icon'
							sizes='180x180'
							href='/apple-touch-icon.png'
						/>
						<link
							rel='icon'
							type='image/png'
							sizes='32x32'
							href='/favicon-32x32.png'
						/>
						<link
							rel='icon'
							type='image/png'
							sizes='16x16'
							href='/favicon-16x16.png'
						/>
						<link rel='manifest' href='/site.webmanifest' />
						<meta name='msapplication-TileColor' content='#da532c' />
						<meta name='theme-color' content='#ffffff' />
					</Head>
					<PrismicRoutingContextProvider
						currentPageId={pageProps.pageId}
						pages={pageProps.allPages}
					>
						<Layout
							key={key}
							footer={pageProps.footer}
							headerNavigation={pageProps.headerNavigation}
						>
							<GoogleReCaptchaProvider
								reCaptchaKey={RECAPTCHA_SITE_KEY}
								scriptProps={{
									async: false,
									defer: false,
									appendTo: 'head',
									nonce: undefined,
								}}
							>
								<Component key={key} {...pageProps} />
							</GoogleReCaptchaProvider>
							<Analytics />
						</Layout>
						<Toaster />
					</PrismicRoutingContextProvider>
				</ThemeProvider>
			</QueryClientProvider>
			<Script
				async
				strategy='beforeInteractive'
				id='cookiehub_script'
				src={process.env.COOKIEHUB_PATH}
			/>
			<CookieHubLoader />
			<Script
				async
				defer
				id='prismic'
				src='https://static.cdn.prismic.io/prismic.js?new=true&repo=kvika'
			/>

			<SegmentScript key='segment-script' />
			<script
				type='text/plain' // intentionally not text/javascript
				id='ortto'
				data-consent='analytics'
				dangerouslySetInnerHTML={{
					__html: `
						window.ap3c = window.ap3c || {};
						var ap3c = window.ap3c;
						ap3c.cmd = ap3c.cmd || [];
						ap3c.cmd.push(function() {
										ap3c.init('Y6zcFz-NHEdxe2una3Zpa2FiYW5raQ', 'https://capture-api.eu.autopilotapp.com/');
										ap3c.track({v: 0});
						});
						ap3c.activity = function(act) { ap3c.act = (ap3c.act || []); ap3c.act.push(act); };
						var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.src = "https://cdneu.net/app.js";
						t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);
					`,
				}}
			/>
			<Script
				type='text/plain'
				data-consent='marketing'
				data-src='https://www.youtube.com'
			/>
		</>
	)
}

export default MyApp
