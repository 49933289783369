import { useEffect } from 'react'

export function useOutsideClick(onOutSideClick: () => void, ref: any, ignoredRef?: any) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			if (
				ref.current
				&& !ref.current.contains(event.target)
				&& (!ignoredRef
					|| (
						ignoredRef.current
						&& !ignoredRef.current.contains(event.target)
					))
			) {
				onOutSideClick()
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref, ignoredRef, onOutSideClick])
}
