import { get } from 'lodash'
import React from 'react'
import styled from 'styled-components'

const YT_REGEX = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/

interface Props {
	element: {
		oembed: {
			embed_url: string
		}
	}
}

const YouTube: React.FC<Props> = ({ element }) => {
	const urlMatch = element.oembed.embed_url.match(YT_REGEX)
	// * We can't be certain that a valid embed_url is provided and the regex will match
	// * get() prevents a build error in that scenario
	const videoId = get(urlMatch, '[2]', false)

	if (!videoId) return null

	const src = `https://www.youtube.com/embed/${videoId}?feature=oembed`

	return (
		<StyledYouTubeContainer>
			<StyledIframe
				src={src}
				frameBorder='0'
				loading='eager'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
			/>
		</StyledYouTubeContainer>
	)
}

export default YouTube

const StyledYouTubeContainer = styled.div``

const StyledIframe = styled.iframe`
	width: 100%;
	aspect-ratio: 16/9;
`
