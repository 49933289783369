import * as React from 'react'
import styled from 'styled-components'

import { media } from '../../utils/style-utils'

import { KvikaSVGProps } from './types'

// * iOS doesn't support viewBox attribute and needs explicit height / width
const KVIKA_ICON_RATIO = 123 / 88

const SiteLogo = ({ fill = '#B3894E', height = 88 }: KvikaSVGProps): JSX.Element => {
	const width = typeof height === 'number' ? height * KVIKA_ICON_RATIO : 'auto'

	return (
		<>
			<DefaultSVG height={48} width={123} xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#a)' fillRule='evenodd' clipRule='evenodd' fill='#A28352'>
					<path d='M27.382 47.946h6.873V36.327l-6.873-4.418v16.037ZM13.69 41.127h6.874v-13.8l-6.873-4.473v18.273ZM0 34.255h6.873V18.273L0 13.746v20.509ZM6.873 6.873v11.4l6.818 4.581V6.873H6.873ZM34.364 7.09v29.455l6.872 4.637V7.09h-6.872ZM20.564 0v27.327l6.818 4.582V0h-6.818ZM78.055 16.2h-.491l-2.455 10.473L72.6 16.2h-3.327l4.09 15.491H76.8l4.145-15.49h-2.89ZM63.164 31.691l-4.855-6.6v6.6h-3.163v-15.49h3.163v5.836L62.4 16.2h3.655l-5.291 7.146 6.218 8.345h-3.818ZM116.564 20.237l1.418 5.781h-2.836l1.418-5.781Zm1.527-4.037h-3.382l-4.418 15.491h3.327l.928-3.436h3.927l.927 3.436h3.327l-4.418-15.49h-.218ZM85.09 16.2v7.364l3.164 2.073V16.2h-3.163ZM91.418 27.654l-3.163-2.018v6h3.163v-3.982ZM104.127 31.691l-4.854-6.6v6.6h-3.219v-15.49h3.219v5.836l4.091-5.837h3.654l-5.345 7.146 6.272 8.345h-3.818Z' />
				</g>
			</DefaultSVG>
			<MobileSVG width={28} height={32} fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M18.255 32h4.582v-7.754l-4.582-2.95V32ZM9.127 27.45h4.582v-9.211l-4.582-2.985v12.195ZM0 22.862h4.582V12.196L0 9.174v13.688ZM4.582 4.587v7.609l4.545 3.058V4.587H4.582ZM22.91 4.733V24.39l4.58 3.095V4.733h-4.58Z'
					fill='#A28352'
				/>
				<path fillRule='evenodd' clipRule='evenodd' d='M13.71 0v18.239l4.545 3.058V0h-4.546Z' fill='#A28352' />
			</MobileSVG>
		</>
	)
}

const DefaultSVG = styled.svg`
	${media.tablet`
		display: none;
	`}
`

const MobileSVG = styled.svg`
	display: none;

	${media.tablet`
		display: inline;
	`}
`

export default SiteLogo
