import React from 'react'
import { Elements } from 'prismic-reactjs'
import Link from 'next/link'

import FileIconAnchor from '../FileIconAnchor'
import ListItemBullet from '../icons/ListItemBullet'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'
import MediaLink from '../MediaLink/MediaLink'
import ShortRuler from '../Ruler/ShortRuler'
import { DecoratedHeadingWrapper } from '../RichText/RichText.styles'

import YouTube from './YouTube'

// * Headings supported by design system typography / prismic
const SUPPORTED_HEADINGS = ['heading1', 'heading2', 'heading3', 'heading4']

export interface SerializerOptions {
	enableMediaLinks?: boolean
	enableFileIconAnchors?: boolean
}

const DEFAULT_OPTIONS: SerializerOptions = {
	enableMediaLinks: true,
	enableFileIconAnchors: true,
}

const HtmlSerializer = (
	type,
	element,
	content,
	children,
	key,
	options: SerializerOptions = {
		...DEFAULT_OPTIONS,
	}
) => {
	const pages = usePrismicRoutingContext().pages

	const serializerOptions = {
		...DEFAULT_OPTIONS,
		...options,
	}
	const { enableMediaLinks, enableFileIconAnchors } = serializerOptions

	if (type === Elements.hyperlink) {
		const href = resolvePrismicLink(element.data, pages)

		if (element.data.link_type === 'Media' && enableMediaLinks) {
			return (
				<MediaLink
					key={key}
					showIcon={enableFileIconAnchors}
					href={href ?? '#'}
				>
					{children}
				</MediaLink>
			)
		}

		if (enableFileIconAnchors) {
			return (
				<FileIconAnchor key={key} href={href ?? '#'}>
					{children}
				</FileIconAnchor>
			)
		}

		if (!enableMediaLinks && !enableFileIconAnchors && href) {
			return (
				<Link href={href ?? '#'} key={key}>
					{children}
				</Link>
			)
		}

		return null
	}

	if (type === Elements.embed && element?.oembed?.provider_name === 'YouTube') {
		return <YouTube key={key} element={element} />
	}

	if (
		SUPPORTED_HEADINGS.includes(element.type) &&
		element?.spans[0]?.data?.label === 'decorated-heading'
	) {
		const HeadingType = `h${element.type.substr(
			-1
		)}` as keyof JSX.IntrinsicElements

		return (
			<DecoratedHeadingWrapper key={key}>
				<HeadingType>{children}</HeadingType>
				<ShortRuler width={HeadingType === 'h1' ? '104' : '17'} />
			</DecoratedHeadingWrapper>
		)
	}

	if (
		type === Elements.listItem &&
		element?.spans[0]?.data?.label === 'decorated-list-item'
	) {
		return (
			<li
				key={key}
				style={{
					listStyleType: 'none',
					padding: 0,
					display: 'flex',
					alignItems: 'center',
					margin: 8,
				}}
			>
				<div style={{ width: 24, height: 24, marginTop: -4, marginRight: 8 }}>
					<ListItemBullet />
				</div>
				{children}
			</li>
		)
	}

	if (type === Elements.list) {
		return (
			<ul
				key={key}
				style={{
					listStyleType: 'square',
					paddingLeft: '16px',
					listStylePosition: 'inside',
				}}
			>
				{children}
			</ul>
		)
	}

	return null
}

export default HtmlSerializer
