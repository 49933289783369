import { css, keyframes } from 'styled-components'

const popIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(-4rem);
}
100% {
  opacity: 1;
  transform: none;
}`

const PopInAnimation = css`
	animation: ${popIn} 0.75s ease-out forwards;
`

export { PopInAnimation }
