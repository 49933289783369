import styled, { css } from 'styled-components'

import { media } from '../../../utils/style-utils'

export const DEFAULT_COL_WIDTH = '80px'
export const DEFAULT_GRID_GAP = '16px;'

// Unused
// export const HalfFullBleedGridLeftStyles = css`
// 	display: grid;
// 	grid-column: 1 / span 7;
// 	column-gap: 16px;

// 	${media.desktopLg`
// 		grid-template-columns: auto repeat(6, ${DEFAULT_COL_WIDTH});
// 	`}

// 	${media.desktopSm`
// 	grid-template-columns: 24px repeat(6, 1fr);
// 	`}

// 	${media.phone`
// 	grid-template-columns: 8px repeat(6, 1fr);
// 	column-gap: 8px;
// 	`}
// `
// export const HalfFullBleedGridRightStyles = css`
// 	display: grid;
// 	grid-column: 2 / span 6;
// 	grid-template-columns: repeat(6, 1fr);

// 	${media.desktopLg`
// 		grid-template-columns: auto repeat(6, ${DEFAULT_COL_WIDTH});
// 	`}

// 	${media.desktopSm`
// 		grid-template-columns: 24px repeat(6, 1fr);
// 	`}

// 	${media.phone`
// 	grid-template-columns: 8px repeat(6, 1fr);
// 	column-gap: 8px;
// 	`}
// `

export const HalfGridStyles = css`
	display: grid;
	grid-column: 2 / span 6;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 16px;
`

export const FullBleedStyles = css`
	display: grid;
	grid-column: 1 / -1;
	column-gap: 16px;

	${media.desktopXl`
		grid-template-columns: auto repeat(12, 116px) auto;

	`}

	// * We deduct the gap from the width of the bordering column
	// * e.g. 160px - 16px = 144px

	${media.desktopLg`
		grid-template-columns: 144px repeat(12, 1fr) 144px;
	`}

	${media.desktopSm`
		grid-template-columns: 64px repeat(12, 1fr) 64px;
	`}

	${media.tablet`
		grid-template-columns: 24px repeat(12, 1fr) 24px;

	`}


	${media.phone`
	grid-template-columns: 8px repeat(12, 1fr) 8px;
	column-gap: 8px;
	`}
`

export const GridStyles = css`
	display: grid;
	column-gap: 16px;

	${media.desktopXl`
	grid-template-columns: repeat(12, 116px);
	margin: 0 auto;
	`}

	${media.desktopLg`
		margin: 0 160px;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 16px;
  `}

	${media.desktopSm`
		margin: 0 80px;
		grid-template-columns: repeat(12, 1fr);
		column-gap: 16px;
	`}

	${media.tablet`
		margin: 0 40px;
		column-gap: 16px;
		grid-template-columns: repeat(12, 1fr);
	`}

	${media.phone`
		margin: 0 16px;
		grid-template-columns: repeat(12, 1fr);
		column-gap: 8px;
  `}
`

export const NestedGridStyles = (nrOfCols: number) => css`
	display: grid;
	grid-template-columns: repeat(${nrOfCols}, ${DEFAULT_COL_WIDTH});
	column-gap: 16px;

	${media.desktopLg`
    grid-template-columns: repeat(${nrOfCols}, 1fr);
    column-gap: 16px;
  `}

	${media.desktopSm`
		grid-template-columns: repeat(${nrOfCols}, 1fr);
		column-gap: 16px;
	`}

	${media.phone`
		grid-template-columns: repeat(${nrOfCols}, 1fr);
		column-gap: 8px;
  `}
`

export const HalfGrid = styled.div`
	${HalfGridStyles}
`

export const FullBleedGrid = styled.div<{
	backgroundColor?: 'Gold50' | 'Gold100' | 'Grey50' | 'Grey800'
}>`
	${FullBleedStyles}

	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};
	`};
`

export const Grid = styled.div`
	${GridStyles}
`
