import styled from 'styled-components'



export const StyledTable = styled.table`
	border-spacing: 0;
	font-size: 1rem;
	font-weight: 300;
	width: 100%;
	color: #333;

	p {
		margin: 0;
	}
`
export const StyledTableHeader = styled.th<{
	width?: string
	hasHeader?: boolean
}>`
	font-weight: 500;
	text-align: left;
	padding: ${({ hasHeader }) => (!!hasHeader ? '16px 16px 4px 16px' : 0)};
	border-bottom: 2px solid ${({ theme }) => theme.colors.gold400};

	width: ${({ width }) => (width ? width : 'auto')};
`

export const StyledTableData = styled.td<{ width?: string }>`
	${({ width }) => (width ? `width: ${width}` : '')}
	padding: 16px;
`

export const StyledTableRow = styled.tr`
	:nth-child(odd) {
		background-color: #f6f6f6;
	}
	width: 100%;
`

export const StyledTableContainer = styled.div`
	table {
		border-spacing: 0;
		font-size: 1rem;
		font-weight: 300;
		width: 100%;
		color: #333;
	}

	thead {
		font-size: 1rem;

		th {
			font-weight: 500;
			text-align: left;
			padding: 16px 16px 4px 16px;
			border-bottom: 2px solid ${({ theme }) => theme.colors.gold400};
		}
	}

	tbody {
		td {
			padding: 16px;
		}

		tr:nth-of-type(odd) {
			background: #f6f6f6;
		}
	}
`
